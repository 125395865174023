import React, { useState } from 'react';
import { Typography, Box, IconButton, Select, MenuItem, OutlinedInput, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const DropdownText = ({ initialText, onComplete, options }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(initialText || []);
  const [open, setOpen] = useState(false); // State to control the opening of the dropdown

  const handleEdit = () => {
    setIsEditing(true);
    setOpen(true); // Open the dropdown automatically when editing starts
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(
      typeof value === 'string' ? value.split(', ') : value,
    );
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
    onComplete(selectedOptions); // Complete editing when the dropdown closes
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        '&:hover .edit-icon': {
          visibility: 'visible',
        },
        '.edit-icon': {
          position: 'absolute',
          right: 0,
          visibility: 'hidden', // Initially hide the icon
        }
      }}
    >
      {!isEditing ? (
        <>
          <Typography variant="body1" sx={{ paddingRight: '24px', minWidth: '40px', minHeight: '20px' /* Space for the icon */ }}>{selectedOptions.join(', ')}</Typography>
          <IconButton
            className="edit-icon"
            size="small"
            onClick={handleEdit}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </>
      ) : (
        <Select
          multiple
          open={open}
          value={selectedOptions}
          onChange={handleChange}
          onClose={handleClose}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 224,
                width: 250,
              },
            },
          }}
        >
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={{ fontWeight: selectedOptions.includes(name) ? 600 : 400 }}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      )}
    </Box>
  );
};

export default DropdownText;