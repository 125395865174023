import { Autocomplete, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import RoundTab from "./RoundTab";
import SpeakerCategoriesTab from "./SpeakerCategoriesTab";
import SpeakerTab from "./SpeakerTab";
import TeamTab from "./TeamTab";
import { roomCount } from "../constants";

const languages = [
  {id: 0, code: "he", display: "עברית"},
  {id: 1, code: "en", display: "English" }
]

const hebLocalization = {
  tab: "טאב",
  language: "שפה",
  roomCount: "כמות חדרים",
  teamTab: "טאב קבוצות",
  speakerTab: "טאב דוברים",
  speakerCategories: "קטגוריות דוברים",
  noSpeakerCategories: "אין קטגוריות דוברים",
  roundsTab: "טאב סבבים"
}

const engLocalization = {
  tab: "Tab",
  language: "Language",
  roomCount: "Room count",
  teamTab: "Team Tab",
  speakerTab: "Speaker Tab",
  speakerCategories: "Speaker Categories",
  noSpeakerCategories: "No speaker categories",
  roundsTab: "Round Results"
}

const localizations = {
  "he": hebLocalization,
  "en": engLocalization
}

const Tabs = ({
}) => {
  const [tabs, setTabs] = useState(null);
  const [language, setLanguage] = useState("he");
  const [localization, setLocalization] = useState(hebLocalization);
  const [selectedTab, setSelectedTab] = useState(null);
  const [inrounds, setInrounds] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const selectedTab = searchParams.get("selectedTab")
    const selectedLanguage = searchParams.get("language")
    if (selectedTab) {
      setSelectedTab(selectedTab)
    }
    if (selectedLanguage) {
      setLanguage(selectedLanguage)
    }
    if (!tabs) {
      getData(selectedTab)
    }
  }, [searchParams])

  useEffect(() => {
    setLocalization(localizations[language])
  }, [language])

  const updateSelectedTab = (selectedTab) => {
    searchParams.set("selectedTab", selectedTab);
    setSearchParams(searchParams);
    setSelectedTab(selectedTab)
  }

  const updateSelectedLang = (selectedLanguage) => {
    let langCode = displayToCode(selectedLanguage)
    searchParams.set("language", langCode);
    setSearchParams(searchParams);
  }

  const convertProp = (val, currProp, targetProp) => languages.filter(x => x[currProp] == val)[0][targetProp]

  const codeToDisplay = code => convertProp(code, "code", "display")

  const displayToCode = display => convertProp(display, "display", "code")

  const getData = async (selectedTab) => {
    let res = await fetch(window.location.hostname.includes("localhost") ? 'tabs.json' : '/api/get_tabs', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    let tabs = await res.json()
    setTabs(tabs)
    if (!selectedTab) {
      updateSelectedTab(Object.keys(tabs)[0])
    }
  }

  useEffect(() => {
    if (!tabs || !selectedTab || !(selectedTab in tabs)) return
    setInrounds(tabs[selectedTab].round
      .filter(round => round._attributes.elimination == "false")
      .map(round => round._attributes.abbreviation))
  }, [tabs, selectedTab])

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr', justifyItems: 'center' }}>
      {tabs && <Autocomplete
        disablePortal
        id="tab"
        value={selectedTab}
        options={Object.keys(tabs)}
        sx={{ width: '300px', marginTop: "10px" }}
        onInputChange={(event, newTabName) => updateSelectedTab(newTabName)}
        renderInput={(params) => <div>
          <TextField {...params} label={localization.tab} />
        </div>}
      />}
      {tabs && <Autocomplete
        disablePortal
        id="lang"
        value={codeToDisplay(language)}
        options={languages.map(x => x.display)}
        sx={{ width: '300px', marginTop: "10px" }}
        onInputChange={(_, newLangName) => updateSelectedLang(newLangName)}
        renderInput={(params) => <div>
          <TextField {...params} label={localization.language} />
        </div>}
      />}
      {tabs && selectedTab && selectedTab in tabs &&
        <div style={{ marginTop: '30px' }}>
          <Typography component="div" gutterBottom>{localization.roomCount}: {roomCount(tabs[selectedTab])}</Typography>
          <Typography variant="h4" component="div" gutterBottom>
            {localization.teamTab}
          </Typography>
          <TeamTab tab={tabs[selectedTab]} inrounds={inrounds} />
          <Typography style={{ marginTop: '30px' }} variant="h4" component="div" gutterBottom>
            {localization.speakerTab}
          </Typography>
          <SpeakerTab tab={tabs[selectedTab]} inrounds={inrounds} />
          <Typography style={{ marginTop: '30px' }} variant="h4" component="div" gutterBottom>
            {localization.speakerCategories}
          </Typography>
          <SpeakerCategoriesTab tab={tabs[selectedTab]} inrounds={inrounds} localization={localization} />
          <Typography style={{ marginTop: '30px' }} variant="h4" component="div" gutterBottom>
            {localization.roundsTab}
          </Typography>
          {tabs[selectedTab].round.map((round, i) => (
            <RoundTab key={i} tab={tabs[selectedTab]} roundIndex={i} />
          ))
          }
        </div>}
    </div>
  );
};

export default Tabs;
