import {  Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import SpeakerTab from "./SpeakerTab";

const SpeakerCategoriesTab = ({ tab, inrounds, localization }) => {
  const [speakerTab, setSpeakerTab] = useState(null);

  useEffect(() => {
    if (!tab) return
    let speakerTab = []
    let speakerCategories = tab["speaker-category"]
    if (speakerCategories) {
      let speakerCatArr = []
      if (Array.isArray(speakerCategories)) {
        speakerCatArr = speakerCategories
      } else {
        speakerCatArr = [speakerCategories]
      }
      speakerTab = speakerCatArr.map(x => { return { name: x._text, id: x._attributes.id } })
      setSpeakerTab(speakerTab)
    } else {
      setSpeakerTab(null)
    }
  }, [tab])

  return (
    <>
      {speakerTab && speakerTab.map((category) => (
        <React.Fragment key={category.id}>
          <Typography variant="h6" component="div" gutterBottom>
            {category.name} Tab
          </Typography>
          <SpeakerTab tab={tab} inrounds={inrounds} categoryId={category.id} />
        </React.Fragment>
      ))}
      {!speakerTab && <div>{localization.noSpeakerCategories}</div>}
    </>
  );
};

export default SpeakerCategoriesTab;
