import React, { useState } from "react";
import SingleListRenderer from "./SingleListRenderer";

const CompetitionAdjudicators = ({ chairs, wings, trainees }) => {
    return (
        <div>
            {SingleListRenderer(chairs, "צ'רים")}
            {SingleListRenderer(wings, "ווינגים")}
            {SingleListRenderer(trainees, "טרייניז")}
        </div>
    )
}

export default CompetitionAdjudicators