import React, { useState, useEffect } from 'react';
import { Container, Content, RTableCell, Section, Text } from './styles';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { categoryDic, renderBoolean, roleDic, sleep, typeDic } from '../constants';

interface ReviewEntriesProps {

}

type Outround = {
  category: string;
  type: string;
  role: string;
};

export const ReviewEntries = ({
  ...props
}: ReviewEntriesProps) => {
  const [data, setData] = useState<{ [name: string]: any } | null>(null);
  const [isEmpty, setIsEmpty] = useState(false);

  React.useEffect(() => {
    fetchData().catch((error) => { console.error("Unable to fetch data:", error) })
  }, [])

  const fetchData = async () => {
    let res = await fetch("/api/get_new_entries")
    if (!res.ok) {
      await sleep(500)
      fetchData()
      return
    }
    let data = await res.json()
    if (Object.keys(data).length == 0) {
      setIsEmpty(true)
    }
    setData(data)
  }

  const approveEntry = async (key: string) => {
    try {
      let res = await fetch(`/api/approve_entry/${key}`)
      if (!res.ok) {
        console.error("Unable to approve entry:", res)
        return
      }
      window.location.reload()
    } catch (e) {
      console.error("Unable to approve entry:", e)
    }
  }

  const rejectEntry = async (key: string) => {
    try {
      let res = await fetch(`/api/reject_entry/${key}`)
      if (!res.ok) {
        console.error("Unable to approve entry:", res)
        return
      }
      window.location.reload()
    } catch (e) {
      console.error("Unable to approve entry:", e)
    }
  }

  const renderOutround = ({ type, category, role }: Outround) => {
    if (type == "N/A" || category == "N/A" || role == "N/A") {
      return "ברייק עם מידע חסר";
    }
    return `${roleDic[role]} ${typeDic[type]} ${categoryDic[category]}`
  }

  return (
    <Container>
      {isEmpty && <Section>אין פה כלום!</Section>}
      {data && Object.entries(data).map(([key, { name, competition, year, ca, dca, aca, chair, outrounds }]) => (
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ textAlign: 'right' }} width="500px">
                  <Content>
                    <Section>
                      <b>{key}</b>
                    </Section>
                    <Section>
                      <Text>שם: {name}</Text>
                      <Text>תחרות: {competition}</Text>
                      <Text>שנה: {year}</Text>
                    </Section>
                    <Section>
                      <Table style={{ width: '200px' }}>
                        <TableBody>
                          <TableRow>
                            <RTableCell>CA</RTableCell>
                            <RTableCell>{renderBoolean(ca)}</RTableCell>
                          </TableRow>
                          <TableRow>
                            <RTableCell>DCA</RTableCell>
                            <RTableCell>{renderBoolean(dca)}</RTableCell>
                          </TableRow>
                          <TableRow>
                            <RTableCell>ACA</RTableCell>
                            <RTableCell>{renderBoolean(aca)}</RTableCell>
                          </TableRow>
                          <TableRow>
                            <RTableCell>צ'ר אינראונד</RTableCell>
                            <RTableCell>{renderBoolean(chair)}</RTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Section>
                    <b>אאוטראונדס</b>
                    {outrounds &&
                      <Section>
                        {outrounds.map((outround: Outround) => (
                          <Text>
                            {renderOutround(outround)}
                          </Text>
                        ))}
                      </Section>
                    }
                    <Section className='horizontal'>
                      <Button variant="contained" color='success' onClick={_ => approveEntry(key)}>✔️</Button>
                      <Button variant="contained" color='error' onClick={_ => rejectEntry(key)}>✘</Button>
                    </Section>
                  </Content>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ))
      }
    </Container >
  );
};
