import { Button } from "@mui/material";
import React from "react";

export default function ToggleText({ content }) {
    const [show, setShow] = React.useState(false);

    const toggleShow = () => {
        setShow(!show)
    };

    return (
        <div>
            <Button onClick={toggleShow}>{show ? "הסתרה" : "הצגה"}</Button>
            {show && content}
        </div>
    );
}