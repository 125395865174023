export const defaultYear = "2023";

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const roleDic: { [key: string]: string; } = {
    "chair": "צ'ר",
    "wing": "ווינג",
    "trainee": "ברייק טרייני"
}


export const typeDic: { [key: string]: string; } = {
    "GF": "גמר",
    "SF": "חצי גמר",
    "QF": "רבע גמר",
    "OF": "שמינית גמר",
    "PDSF": "PDS",
    "PDQF": "PDQ",
    "PDOF": "PDO"
}

export const categoryDic: { [key: string]: string; } = {
    "proam": "מעורב",
    "open": "פתוח",
    "junior": "ג'וניורים",
    "freshmen": "פרשמנים",
    "esl": "ESL",
    "efl": "EFL",
    "gold": "זהב",
    "silver": "כסף",
    "bronze": "ארד"
}

export const outroundTemplate: { [key: string]: string; } = {
    "panel": "",
    "type": "GF",
    "category": "open"
}

export const renderBoolean = (bool: boolean) => {
    return bool ? "✔️" : ""
}

export const roomCount = (tab: any) => {
    return Math.floor(tab.participants.team.length / 4)
  }