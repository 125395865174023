import styled from 'styled-components';
import { TableCell } from "@mui/material";

export const HebrewTableCell = styled(TableCell)`
    direction: rtl;
    text-align: right;
`;

export const CustomList = styled.ul`
    list-style-type: none;
    padding: 0;
    -webkit-padding-start: 0;
`;
// ${({ noStyle }) => noStyle && `
// `}