import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Link, List, ListItem, Container, CircularProgress, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SeasonList = ({ seasons }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Container>
      <TextField
        label="חיפוש תחרויות"
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={handleSearchChange}
      />
      {seasons.map((season) => (
        <SeasonItem key={season.id} season={season} searchQuery={searchQuery} />
      ))}
    </Container>
  );
};

const SeasonItem = ({ season, searchQuery }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [competitions, setCompetitions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (season.type === 'centralized' && season.url.includes('calicotab.com')) {
      setLoading(true);
      fetch('/api/fetchTournaments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ url: season.url })
      })
        .then(response => response.json())
        .then(data => {
          setCompetitions(data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching centralized tournaments:', error);
          setLoading(false);
        });
    } else {
      setCompetitions(season.tabs);
    }
  }, [season]);

  useEffect(() => {
    setIsExpanded(searchQuery.length > 0);
  }, [searchQuery]);

  const filteredCompetitions = competitions.filter(competition =>
    competition.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {filteredCompetitions && filteredCompetitions.length > 0 && <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">{season.season}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? <CircularProgress /> : <CompetitionList competitions={filteredCompetitions} />}
        </AccordionDetails>
      </Accordion>}
    </>
  );
};

const CompetitionList = ({ competitions }) => {

  const getDirection = (name) => {
    return name.match(/[\u0590-\u05FF]/) ? 'rtl' : 'ltr';
  }

  return (
    <List>
      {competitions.map((competition) => (
        <ListItem key={competition.id}>
          {competition.type == "missing" ? (
            <Typography>{competition.name} (טאב חסר)</Typography>
          ) : (
            <Link href={competition.url} style={{ direction: getDirection(competition.name) }} target="_blank" rel="noopener">{competition.name}</Link>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default SeasonList;
