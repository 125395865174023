import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { displayRank, rankByMetric } from '../Rank';
import { verifyMap } from "../utils";

function getStandardDeviation(array) {
  const n = array.length
  if (n == 0) return 0
  const mean = array.reduce((a, b) => a + b) / n
  return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}


const SpeakerTab = ({ tab, inrounds, categoryId = null }) => {
  const [speakerTab, setSpeakerTab] = useState(null);

  useEffect(() => {
    if (!tab) return
    let speakerTab = []
    tab.participants.team.forEach(team => {
      verifyMap(team.speaker).forEach(speaker => {
        if (!categoryId || speaker._attributes.categories.includes(categoryId)) {
          let name = speaker._text
          let institution = getSpeakerInstitution(speaker)
          let roundsArr = getSpeakerPerformanceInRounds(speaker)
          let speaks = roundsArr.map(round => round.speaks)
          let rounds = roundsArr.reduce((prev, curr) => ({ ...prev, [curr.round]: { rank: curr.rank, speaks: curr.speaks } }), {})
          let totalSpeaks = speaks.reduce((prev, curr) => prev + curr, 0)
          let avgSpeaks = speaks.filter(x => x > 0).reduce((prev, curr, _, { length }) => prev + curr / length, 0).toFixed(2)
          let stdev = getStandardDeviation(speaks.filter(x => x > 0)).toFixed(2)
          let teamName = team._attributes.name
          speakerTab.push({ name, teamName, institution, rounds, totalSpeaks, avgSpeaks, stdev })
        }
      })
    })
    rankByMetric(speakerTab, ((a, b) => b.totalSpeaks - a.totalSpeaks));
    setSpeakerTab(speakerTab)
  }, [tab])

  const getSpeakerInstitution = (speaker) => {
    if (!tab.institution) return ""
    let institutionObj = tab.institution.find(institution => institution._attributes.id == speaker._attributes.institutions)
    return institutionObj ? institutionObj._text : ""
  }

  const getSpeakerPerformanceInRounds = (speaker) => {
    return tab.round
      .filter(round => round._attributes.elimination == "false")
      .map(round => {
        return {
          round: round._attributes.abbreviation,
          speaks: +parseFloat(getSpeechScoreInRound(round, speaker)).toFixed(1)
        }
      })
  }

  const getSpeechScoreInRound = (round, speaker) => {
    let speeches = round.debate.flatMap(debate =>
      debate.side.flatMap(side =>
        side.speech.flatMap(speech =>
          speech._attributes.speaker == speaker._attributes.id ? [speech] : [])
      )
    )
    return speeches.reduce((prev, curr, _, { length }) => (Math.max(parseFloat(prev), parseFloat(curr.ballot._text))).toString(), "0")
  }
  return (
    <TableContainer style={{ direction: 'ltr' }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell >Rank</TableCell>
            <TableCell >Speaker</TableCell>
            <TableCell >Team</TableCell>
            <TableCell >Institution</TableCell>
            {inrounds && inrounds.map(inround => (
              <TableCell key={inround}>{inround}</TableCell>
            ))}
            <TableCell >Total</TableCell>
            <TableCell >Avg</TableCell>
            <TableCell >StDev</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {speakerTab && speakerTab.map((speaker, i) => (
            <TableRow key={i}>
              <TableCell >{displayRank(speaker.rank)}</TableCell>
              <TableCell >{speaker.name}</TableCell>
              <TableCell >{speaker.teamName}</TableCell>
              <TableCell >{speaker.institution}</TableCell>
              {inrounds && inrounds.map(inround => (
                <TableCell key={inround}>{speaker.rounds[inround].speaks}</TableCell>
              ))}
              <TableCell >{speaker.totalSpeaks}</TableCell>
              <TableCell >{speaker.avgSpeaks}</TableCell>
              <TableCell >{speaker.stdev}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SpeakerTab;
