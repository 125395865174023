export function getChairs(judges) {
    let ordered = Object.entries(judges)
        .map(x => {
            let all = Object.values(x[1].competitions).filter(y => y.outroundChair).length
            let official = Object.values(x[1].competitions).filter(y => y.outroundChair && y.isOfficial).length

            let officialNonAteam = Object.values(x[1].competitions)
                .filter(y => y.outroundChair && y.isOfficial && !(y.ca || y.dca || y.aca)).length
            let percentOfficial = official / Math.max(Object.values(x[1].competitions).filter(y => y.isOfficial).length, 1)
            let percentAll = all / Math.max(Object.values(x[1].competitions).length, 1)
            
            return [
                x[0],
                officialNonAteam,
                official,
                all,
                `${(percentOfficial * 100).toFixed()}%`,
                `${(percentAll * 100).toFixed()}%`
            ]
        })
        .sort((a, b) => b[1] - a[1])
    return ordered
}