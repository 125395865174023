import styled from 'styled-components';

export const ColumnGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    max-width: 100%;

    @media screen and (min-width: 765px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const MotionsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    max-width: 100%;
    @media screen and (min-width: 765px) {
        grid-template-columns: 1fr 1fr;
    }
`;