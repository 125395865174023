import React, { useCallback, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Button, Chip, Paper, Slider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { MotionsGrid } from "./styles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function RandomMotion({ }) {
  const [data, setData] = React.useState(null)
  const [queriedMotions, setQueriedMotions] = React.useState([])
  const [count, setCount] = React.useState(null)
  const [ateam, setAteam] = React.useState([])
  const [relevantMotionsCount, setRelevantMotionsCount] = React.useState(0)
  const [searchQuery, setSearchQuery] = React.useState("")
  const [languages, setLanguages] = React.useState([])
  const [subjects, setSubjects] = React.useState([])
  const [competitions, setCompetitions] = React.useState([])
  const [yearRange, setYearRange] = React.useState([2000, new Date().getFullYear()]); // Default range
  const [availableYears, setAvailableYears] = React.useState([2000, new Date().getFullYear()]);

  const query = useQuery()
  const filter = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option,
  });

  const onButtonClick = useCallback(() => {
    window.history.replaceState(null, "Debate Land", `/${getQueryParams()}`)
    refreshMotionsQuery(competitions, count, ateam, subjects, languages, searchQuery, data)
  })

  const refreshMotionsQuery = (competitions, count, ateam, subjects, languages, searchQuery, data) => {
    let compClause = x => competitions.length == 0 || competitions.some(comp => x.competition.toLowerCase().includes(comp.toLowerCase()))
    let ateamClause = x => ateam.length == 0 || ateam.some(judge => x.ateam.toLowerCase().includes(judge.toLowerCase()))
    let subjectsClause = x => subjects.length == 0 || subjects.some(subject => x.subjectA.toLowerCase() == subject.toLowerCase() || x.subjectB.toLowerCase() == subject.toLowerCase() || x.subjectC.toLowerCase() == subject.toLowerCase())
    let languagesClause = x => languages.length == 0 || languages.some(language => x.language.toLowerCase().includes(language.toLowerCase()))
    let searchQueryClause = x => !searchQuery || x.info_slide.toLowerCase().includes(searchQuery.toLowerCase()) || x.motion.toLowerCase().includes(searchQuery.toLowerCase())
    let yearClause = x => {
      const motionYear = new Date(x.date).getFullYear();
      return motionYear >= yearRange[0] && motionYear <= yearRange[1];
    };
    let queriedMotions = data.motions.filter(x => compClause(x) && ateamClause(x) && subjectsClause(x) && languagesClause(x) && searchQueryClause(x) && yearClause(x))
    let shuffled = queriedMotions.sort(() => 0.5 - Math.random())
    setQueriedMotions(queriedMotions.slice(0, Math.min(shuffled.length, count)))
    setRelevantMotionsCount(shuffled.length)
  }

  useEffect(() => {
    getMotions()
    setCount(query.get("count") ? parseInt(query.get("count")) : 1)
    if (query.get("ateam")) setAteam(query.get("ateam").split(","))
    if (query.get("languages")) setLanguages((query.get("languages") || "").split(","))
    if (query.get("subjects")) setSubjects(query.get("subjects").split(";"))
    if (query.get("searchQuery")) setSearchQuery(query.get("searchQuery"))
    if (query.get("competitions")) setCompetitions(query.get("competitions").split(","))
  }, [query])

  useEffect(() => {
    if (!data) return
    refreshMotionsQuery(competitions, count, ateam, subjects, languages, searchQuery, data)
  }, [data])

  const getMotions = () => {
    try {
      fetch(`/api/get_motions`,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "GET",
        })
        .then(res => res.json())
        .then(data => {
          setData(data.data);
          setAvailableYears([data.data.minYear, data.data.maxYear]);
          setYearRange([data.data.minYear, data.data.maxYear]);
        })
        .catch(err => { console.error("Unable to fetch JSON:", err) })
    } catch (error) {
      console.error("Unable to fetch JSON:", error);
    }
  }
  const getQueryParams = () => {
    let params = new URLSearchParams()
    params.append("count", count.toString())
    if (ateam && ateam.join()) {
      params.append("ateam", ateam.join())
    }
    if (searchQuery) {
      params.append("searchQuery", searchQuery)
    }
    if (languages && languages.join()) {
      params.append("languages", languages.join())
    }
    if (subjects && subjects.join(";")) {
      params.append("subjects", subjects.join(";"))
    }
    if (competitions && competitions.join()) {
      params.append("competitions", competitions.join())
    }

    let res = params.toString()
    return res == "" ? "" : `?${res}`
  }

  const renderChecklistOption = (props, option, { selected }) => (
    <li {...props}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={selected}
      />
      {option}
    </li>
  )

  const subjectAvailable = motion => {
    return [motion.subjectA, motion.subjectB, motion.subjectC, motion.openSubjects]
      .filter(x => x)
      .filter(x => x != '')
      .length > 0
  }

  const renderSubjects = motion => {
    return [motion.subjectA, motion.subjectB, motion.subjectC, motion.openSubjects]
      .filter(x => x)
      .filter(x => x != '')
      .join(", ")
  }

  return (
    <>
      {data &&
        <div style={{ display: 'grid', marginTop: '50px', alignItems: 'center', justifyContent: 'center', gridTemplateRows: 'auto 1fr', gap: '20px' }}>
          <h3>הגרלת מושנים</h3>
          <p>המושנים נלקחים מתוך טבלת המושנים הליגתית, <a href="https://docs.google.com/spreadsheets/d/1uGW-N8yWyRaKWi1-hm5xUpG_lblsbQ2DPeNd6h1O7Fw/edit?usp=sharing">לחצו כאן</a> למעבר לטבלה המלאה.
          </p>
          <MotionsGrid>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '80%' }}>
              <b>סינון מחדש:</b>
              <Button onClick={onButtonClick} variant="contained">לחצו לסינון מחדש</Button>
              <Autocomplete
                freeSolo
                id="searchQuery"
                options={[]}
                value={searchQuery}
                onInputChange={(event, val) => {
                  setSearchQuery(val)
                }}
                renderInput={(params) => <TextField {...params} label={"הזינו טקסט לחיפוש מילים במושן"} />} />

              <TextField
                id="outlined-number"
                label="כמות המושנים הרצויה"
                value={count}
                onChange={(e) => setCount(parseInt(e.target.value))}
                type="number"
                style={{ direction: 'ltr' }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={data.judges}
                value={ateam}
                disableCloseOnSelect
                onChange={(e, v) => setAteam(v)}
                style={{ direction: 'ltr' }}
                getOptionLabel={(option) => option}
                renderOption={renderChecklistOption}
                renderInput={(params) => (
                  <TextField {...params} label="סינון לפי חברי אייטים" placeholder="בחרו חברי אייטים" />
                )}
              />

              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={data.languages}
                disableCloseOnSelect
                value={languages}
                onChange={(e, v) => setLanguages(v)}
                style={{ direction: 'ltr' }}
                getOptionLabel={(option) => option}
                renderOption={renderChecklistOption}
                renderInput={(params) => (
                  <TextField {...params} label="סינון לפי שפת המושן" placeholder="בחרו שפות" />
                )}
              />

              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={data.subjects}
                disableCloseOnSelect
                value={subjects}
                onChange={(e, v) => setSubjects(v)}
                style={{ direction: 'ltr' }}
                getOptionLabel={(option) => option}
                renderOption={renderChecklistOption}
                renderInput={(params) => (
                  <TextField {...params} label="סינון לפי נושא המושן" placeholder="בחרו נושאים" />
                )}
              />

              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={data.competitions}
                disableCloseOnSelect
                value={competitions}
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                filterOptions={(options, params) => {
                  const filtered = filter(options, params)
                  const { inputValue } = params
                  return inputValue ? [inputValue, ...filtered] : filtered
                }}
                onChange={(e, v) => setCompetitions(v)}
                style={{ direction: 'ltr' }}
                getOptionLabel={(option) => option}
                renderOption={renderChecklistOption}
                renderInput={(params) => (
                  <TextField {...params} label="סינון לפי שם התחרות" placeholder="הקלידו שם תחרות מלא או חלקי ולחצו אנטר" />
                )}
              />
              <div>
                <Slider
                  value={yearRange}
                  onChange={(e, newValue) => setYearRange(newValue)}
                  valueLabelDisplay="auto"
                  min={availableYears[0]}
                  max={availableYears[1]}
                  marks={[
                    { value: availableYears[0], label: availableYears[0].toString() },
                    { value: availableYears[1], label: availableYears[1].toString() }
                  ]}
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
              {queriedMotions.map((motion) => (
                <TableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ textAlign: 'right' }} width="500px">
                          {motion.info_slide && <p>{motion.info_slide}</p>}
                          <p><b>{motion.motion}</b></p>
                          <p style={{ marginTop: '50px', fontSize: '12px' }}>
                            מתוך '{motion.competition}' / סיבוב {motion.round} / {motion.date}
                            {motion.ateam && <span><br />אייטים: {motion.ateam}</span>}
                            {subjectAvailable(motion) && <span><br />נושאים: {renderSubjects(motion)}</span>}
                          </p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ))}
              <p>המושנים הוגרלו מתוך {relevantMotionsCount} מושנים אפשריים על-פי הסינון הנוכחי</p>
            </div>
          </MotionsGrid>
        </div>
      }
    </>
  );
}