import React from "react";
import { Table, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export default function Outrounds({
  data
}) {
  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '100vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width="16%" align="right">שם</TableCell>
                <TableCell width="16%" align="right">צ׳ר באאוטראונדס של רשמיות שלא היה באייטים</TableCell>
                <TableCell width="16%" align="right">צ׳ר באאוטראונדס של רשמיות כולל אייטים</TableCell>
                <TableCell width="16%" align="right">צ׳ר באאוטראונדס של כל התחרויות</TableCell>
                <TableCell width="16%" align="right">אחוז צ׳ר באאוטראונדס מכל התחרויות הרשמיות שהשתתף</TableCell>
                <TableCell width="16%" align="right">אחוז צ׳ר באאוטראונדס מכל התחרויות שהשתתף בכלל</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row[0]}>
                  <TableCell align="right">
                    {row[0]}
                  </TableCell>
                  <TableCell align="right">
                    {row[1]}
                  </TableCell>
                  <TableCell align="right">
                    {row[2]}
                  </TableCell>
                  <TableCell align="right">
                    {row[3]}
                  </TableCell>
                  <TableCell align="right">
                    {row[4]}
                  </TableCell>
                  <TableCell align="right">
                    {row[5]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}