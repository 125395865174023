import React, { useState } from 'react';
import { Button, TextField, Typography, Box, FormControlLabel, Checkbox, Alert, Tooltip } from '@mui/material';

export const TabImporter = ({
  ...props
}) => {
  const [siteUrl, setSiteUrl] = useState('');
  const [sheetId, setSheetId] = useState('');
  const [tournamentSlug, setTournamentSlug] = useState('');
  const [apiToken, setApiToken] = useState('');
  const [importTeams, setImportTeams] = useState(false);
  const [importAdjudicators, setImportAdjudicators] = useState(false);

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let res = await fetch("/api/send_data_to_tabbycat", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        siteUrl,
        sheetId: extractSheetId(sheetId),
        tournamentSlug,
        apiToken,
        importTeams,
        importAdjudicators
      })
    })
    if (!res.ok) {
      setError(await res.text())
      return
    }
    let data = await res.json()
    setResponse(data)
  };

  const extractSheetId = (input) => {
    const regex = /\/d\/([a-zA-Z0-9-_]+)/;
    const match = input.match(regex);
    return match ? match[1] : input;
  };

  return (
    <Box sx={{ direction: 'ltr', alignItems: 'center', '& .MuiTextField-root': { m: 1 }, '& .MuiFormControlLabel-root': { m: 1 }, '& .MuiButton-root': { m: 1 } }}>
      <Typography variant="h4" gutterBottom>
        Import Google Sheet to Tabbycat
      </Typography>
      <div style={{ display: 'flex', margin: 'auto', justifyContent: 'center', flexWrap: 'wrap', maxWidth: '400px', flexDirection: 'column' }}>
        <TooltipInput
          name="siteUrl"
          tooltip="It's probably https://idltournaments2024.calicotab.com/"
          label="Tabbycat URL"
          placeholder='https://idltournaments2024.calicotab.com/'
          required
          onChange={e => setSiteUrl(e.target.value)}
          value={siteUrl} />
        <br />
        <TooltipInput
          name="sheetId"
          tooltip={<SheetIdExplanationCopyPasta />}
          label="Google Sheet URL"
          placeholder='https://docs.google.com/spreadsheets/d/ABC/edit'
          required
          onChange={e => setSheetId(e.target.value)}
          value={sheetId} />
        <Typography variant="body2" fullWidth style={{ display: "inline-block", wordBreak: 'break-all', whiteSpace: "pre-line" }} color='error'><SheetIdExplanationCopyPasta /></Typography>
        <br />
        <TooltipInput
          name="tournamentSlug"
          tooltip="If your tournament's full URL is https://idltournaments2024.calicotab.com/bigbrother2024, then the tournament slug is bigbrother2024"
          label="Tournament Slug"
          placeholder='bigbrother2024'
          required
          onChange={e => setTournamentSlug(e.target.value)}
          value={tournamentSlug} />
        <TooltipInput
          name="apiToken"
          tooltip={`Sign in to Tabbycat and click on "Get API Token / Change Password". Your API Token will be at the top of the page`}
          label="Tabbycat API Token"
          required
          placeholder='1234567890abcdef'
          onChange={e => setApiToken(e.target.value)}
          value={apiToken} />
        <FormControlLabel fullWidth control={<Checkbox onChange={e => setImportTeams(e.target.checked)} checked={importTeams} />} label={`Import Teams from "teams" sheet`} />
        <FormControlLabel fullWidth control={<Checkbox onChange={e => setImportAdjudicators(e.target.checked)} checked={importAdjudicators} />} label={`Import Adjudicators from "adjudicators" sheet`} />
        <Button onClick={handleSubmit} fullWidth variant="contained">Submit</Button>
        {error && <Alert severity="error">{error}</Alert>}
      </div>
      {response && response.teams && <Typography style={{ whiteSpace: 'pre-line' }} variant="body1" gutterBottom>Teams:<br />{response.teams.join('\n')}</Typography>}
      <br />
      {response && response.adjudicators && <Typography style={{ whiteSpace: 'pre-line' }} variant="body1" gutterBottom>Adjudicators:<br />{response.adjudicators.join('\n')}</Typography>}
    </Box>
  );
};

const SheetIdExplanationCopyPasta = () => {
  return (
    <span>1. Clone this template and fill it with your data:
      <br />
      https://docs.google.com/spreadsheets/d/1r5JwdSFTpOykkyy1EvtpyP4oDWVGvQ7a_Bx1AKQqvp0/edit?usp=sharing
      <br />
      2. Set your copy to "Anyone with the link can view" mode
      <br />
      3. Paste your copy's URL here
    </span>
  )
}

const TooltipInput = ({ tooltip, ...props }) => {
  return (
    <Tooltip arrow title={<Typography style={{ direction: 'ltr', whiteSpace: 'pre-line' }} variant="body2">{tooltip}</Typography>} style={{ direction: 'ltr' }}>
      <TextField
        variant="outlined" InputLabelProps={{ shrink: true }} fullWidth
        {...props}
      />
    </Tooltip>
  )
}