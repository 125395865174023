import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { displayRank, rankByMetric } from "../Rank";
import { verifyMap } from "../utils";

const TeamTab = ({ tab, inrounds }) => {
  const [teamTab, setTeamTab] = useState(null);

  useEffect(() => {
    if (!tab) return
    let teamTab = []
    tab.participants.team.forEach(team => {
      let name = team._attributes.name
      let institutions = [...new Set(verifyMap(team.speaker).map(getSpeakerInstitution))].join(", ")
      let roundsArr = getTeamPerformanceInRounds(team)
      let rounds = roundsArr.reduce((prev, curr) => ({ ...prev, [curr.round]: { rank: curr.rank, speaks: curr.speaks } }), {})
      let totalPoints = roundsArr.reduce(sumPoints, 0)
      let totalSpeaks = roundsArr.reduce((prev, curr) => prev + parseFloat(curr.speaks), 0)
      teamTab.push({ name, institutions, rounds, totalPoints, totalSpeaks })
    })
    rankByMetric(teamTab, (a, b) => b.totalPoints - a.totalPoints || b.totalSpeaks - a.totalSpeaks);
    setTeamTab(teamTab)
  }, [tab])

  const getSideForTeamInRound = (team, round) => {
    return round.debate.flatMap(debate =>
      debate.side.flatMap(side =>
        side._attributes.team == team._attributes.id ? [side] : []
      )
    )[0]
  }

  const getTeamPerformanceInRounds = (team) => {
    return tab.round
      .filter(round => round._attributes.elimination == "false")
      .map(round => {
        let side = getSideForTeamInRound(team, round)
        if (!side) {
          return {
            round: round._attributes.abbreviation,
            rank: "4",
            speaks: "0"
          }
        }
        return {
          round: round._attributes.abbreviation,
          rank: side.ballot._attributes.rank,
          speaks: side.ballot._text
        }
      })
  }

  const getSpeakerInstitution = (speaker) => {
    if (!tab.institution) return ""
    let institutionObj = tab.institution.find(institution => institution._attributes.id == speaker._attributes.institutions)
    return institutionObj ? institutionObj._text : ""
  }

  const sumPoints = (prev, curr) => {
    if (curr.rank == "1") return prev + 3
    if (curr.rank == "2") return prev + 2
    if (curr.rank == "3") return prev + 1
    return prev
  }

  return (
    <TableContainer style={{ direction: 'ltr', marginTop: '30px' }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell >Rank</TableCell>
            <TableCell >Team</TableCell>
            <TableCell >Institution</TableCell>
            {inrounds && inrounds.map(inround => (
              <TableCell key={inround}>{inround}</TableCell>
            ))}
            <TableCell >Points</TableCell>
            <TableCell >Speaks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {teamTab && teamTab.map((team, i) => (
            <TableRow key={i}>
              <TableCell >{displayRank(team.rank)}</TableCell>
              <TableCell >{team.name}</TableCell>
              <TableCell >{team.institutions}</TableCell>
              {inrounds && inrounds.map(inround => (
                <TableCell key={inround}>{team.rounds[inround].rank} ({team.rounds[inround].speaks})</TableCell>
              ))}
              <TableCell >{team.totalPoints}</TableCell>
              <TableCell >{team.totalSpeaks}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeamTab;
