import React, { useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel } from '@mui/material';

const styles = {
  roleList: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '20px',
  },
  roleItem: {
      display: 'flex',
      alignItems: 'center',
  },
  roleLabel: {
      marginLeft: '10px', // Adjust this for indentation
  }
};

const DebateTimekeeperXMLGenerator = () => {
  const [prepTime, setPrepTime] = useState("15:00");
  const [finishTime, setFinishTime] = useState("7:00");
  const [roles, setRoles] = useState([
    "Prime Minister", "Leader of the Opposition", "Deputy Prime Minister",
    "Deputy Leader of the Opposition", "Member for the Government",
    "Member for the Opposition", "Government Whip", "Opposition Whip"
  ]);

  const roleTranslations = {
    "Prime Minister": "Primer ministro",
    "Leader of the Opposition": "Líder de la oposoción",
    "Deputy Prime Minister": "Viceprimer ministro",
    "Deputy Leader of the Opposition": "Vicelíder de la oposoción",
    "Member for the Government": "Miembro del gobierno",
    "Government Whip": "Látigo del gobierno",
    "Member for the Opposition": "Miembro de la oposoción",
    "Opposition Whip": "Látigo de la oposoción"
  };

  const calculateWarningTime = (finishTime) => {
    let [minutes, seconds] = finishTime.split(":").map(Number);
    let totalSeconds = minutes * 60 + seconds;
    let warningSeconds = totalSeconds - 60;

    if (warningSeconds < 0) {
      warningSeconds = 0;
    }

    let warningMinutes = Math.floor(warningSeconds / 60);
    let remainingSeconds = warningSeconds % 60;
    return [warningMinutes, remainingSeconds].map(v => String(v).padStart(2, '0')).join(":");
  };

  const generateXML = () => {
    let warningTime = calculateWarningTime(finishTime);
    let xml = `<?xml version='1.0' encoding='UTF-8'?>\n`;
    xml += `<debate-format schema-version="2.2">\n`;
    xml += `  <name xml:lang="en">British Parliamentary ${finishTime} min</name>\n`;
    xml += `  <name xml:lang="es">Parlamentario Británico ${finishTime} min</name>\n`;
    xml += `    <version>2</version>
                    <languages>
                        <language>en</language>
                        <language>es</language>
                    </languages>
                    <info xml:lang="en">
                        <region>World</region>
                        <level>University</level>
                        <used-at>World Universities Debating Championship</used-at>
                        <used-at>Campeonato Mundial Universitario de Debate en Español</used-at>
                        <description>Four teams of two, ${finishTime}-minute speeches, POIs allowed</description>
                    </info>
                    <info xml:lang="es">
                        <region>Mundo</region>
                        <level>Universidad</level>
                        <used-at>World Universities Debating Championship</used-at>
                        <used-at>Campeonato Mundial Universitario de Debate en Español</used-at>
                        <description>Cuatro equipos de dos oradores, discursos de ${finishTime} minutos, los PdI permitidos</description>
                    </info>
                    <prep-time length="${prepTime}"/>
                    <speech-types>
                        <speech-type ref="all" length="${finishTime}" first-period="normal">
                        <name xml:lang="en">all</name>
                        <name xml:lang="es">todos</name>
                        <bell time="1:00" number="1" next-period="pois-allowed"/>
                        <bell time="${warningTime}" number="1" next-period="warning"/>
                        <bell time="finish" number="2" next-period="overtime"/>
                        </speech-type>
                    </speech-types>`
    xml += `  <speeches>\n`;
    roles.forEach(role => {
      xml += `    <speech type="all">\n`;
      xml += `      <name xml:lang="en">${role}</name>\n`;
      xml += `      <name xml:lang="es">${role in roleTranslations ? roleTranslations[role] : role}</name>\n`;
      xml += `    </speech>\n`;
    });
    xml += `  </speeches>\n`;
    xml += `</debate-format>\n`;
    return xml;
  };

  const handleDownloadXML = () => {
    const xmlData = generateXML();
    const blob = new Blob([xmlData], { type: 'text/xml' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'file.xml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRoleChange = (role) => {
    setRoles(prev => prev.includes(role) ? prev.filter(r => r !== role) : [...prev, role]);
  };

  return (
    <div style={{ direction: 'ltr', alignItems: 'center', justifyContent: 'center' }}>
      <h2>Debate Timekeeper XML Generator</h2>
      <TextField
        label="Prep Time (default 15:00)"
        value={prepTime}
        onChange={(e) => setPrepTime(e.target.value)}
        margin="normal"
      />
      <br />
      <TextField
        label="Finish Time (default 7:00)"
        value={finishTime}
        onChange={(e) => setFinishTime(e.target.value)}
        margin="normal"
      />
      <br />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={styles.roleList}>
          {Object.keys(roleTranslations).map((role, index) => (
            <div key={index} style={styles.roleItem}>
              <Checkbox
                checked={roles.includes(role)}
                onChange={() => handleRoleChange(role)}
              />
              <div style={styles.roleLabel}>{role}</div>
            </div>
          ))}
        </div>
      </div>
      <Button variant="contained" color="primary" onClick={handleDownloadXML}>
        Generate XML
      </Button>
    </div>
  );
};

export default DebateTimekeeperXMLGenerator;
