import React from "react"
import ListRenderer from "./ListRenderer"

const SingleListRenderer = (list, title) => {
    return list && list.length != 0 &&
            <>
                <b>{title}</b>
                <ListRenderer list={list} />
            </>
}

export default SingleListRenderer