function rankConsecutiveByMetric(first, second, metric) {
  if (metric(first, second) == 0) {
    first.rank.isTied = true;
    second.rank = first.rank;
  }
};

export function rankByMetric(members, metric) {
  members.sort(metric);
  members.forEach((member, i) => { member.rank = { index: i + 1, isTied: false } });
  for (var i = 0; i < members.length - 1; i++) {
    rankConsecutiveByMetric(members[i], members[i + 1], metric);
  }
};

export function displayRank(rank) {
  return `${rank.index}${rank.isTied ? "=" : ""}`
}