import React from "react";
import Judges from "./Judges"
import { getByAdjudicators } from './GetByAdjudicators';
import { getChairs } from './GetChairs';
import AddNewBreak from './SubmitNewData/AddNewBreak';
import {
  Route,
  Routes,
  useSearchParams
} from "react-router-dom";
import NavBar from "./NavBar";
import Calculator from './Calculator';
import Outrounds from './Outrounds';
import PracticeList from "./PracticeList";
import RandomMotion from './RandomMotion';
import Tabs from "./Tabs/Tabs";
import Competitions from "./Competitions/Competitions";
import DisclaimerNotice from "./DisclaimerNotice";
import { Button } from "@mui/material";
import { runScript } from "./runScript";
import { isLocal } from "../serviceWorker";
import JudgesCountByRank from "./JudgeCountByRank";
import JudgesTimeline from "./JudgesTimeline";
import { AddCompetition } from "./AddCompetition";
import { ReviewEntries } from "./ReviewEntries";
import { sleep } from "./constants";
import { RoomCounter } from "./RoomCounter";
import DebateTimekeeperXMLGenerator from "./DebaterKeeperGenerator";
import { TabImporter } from "./TabImporter";
import SeasonList from "./TabViewer/SeasonList";
import tabs from "./TabViewer/tabs.json";
import NameReplacementForm from "./ReplaceName";

const Home = () => {
  const [defJson, setDefJson] = React.useState(null)
  const [byAdjudicators, setByAdjudicators] = React.useState(null)
  const [chairData, setChairData] = React.useState(null)
  const [clubAssociations, setClubAssociations] = React.useState(null)
  const [searchParams, setSearchParams] = useSearchParams();
  const newRanks = searchParams.get("newRanks") === "1";

  document.body.setAttribute("dir", "rtl");
  if (byAdjudicators) {
    window.adjs = byAdjudicators
  }

  React.useEffect(() => {
    fetchData().catch((error) => { console.error("Unable to fetch data:", error) })
  }, [])

  const fetchData = async () => {
    let res = await fetch("/api/data")
    if (!res.ok) {
      await sleep(500)
      fetchData()
      return
    }
    let data = await res.json()
    const byAdjudicators = getByAdjudicators(data.data.achievements, newRanks)
    setByAdjudicators(byAdjudicators)
    setClubAssociations(data.data.clubAssociations)
    setChairData(getChairs(byAdjudicators))
    setDefJson(data.data.achievements)
  }

  return (
    <div>
      <NavBar />
      {defJson && isLocal && <Button onClick={() => runScript(defJson)}>Run Script</Button>}
      <Routes>
        <Route path="/practices" element={<PracticeList />} />
        <Route path="/tabimporter" element={<TabImporter />} />
        <Route path="/replacenames" element={<NameReplacementForm />} />
        <Route path="/addbreak" element={defJson && <AddNewBreak judges={byAdjudicators} competitions={defJson} />} />
        <Route path="/addcompetition" element={<AddCompetition />} />
        <Route path="/reviewentries" element={<ReviewEntries />} />
        <Route path="/competitions" element=
          {
            <>
              <DisclaimerNotice />
              {defJson && <Competitions competitions={defJson} />}
            </>
          }
        />
        <Route path="/outrounds" element=
          {<>
            <DisclaimerNotice />
            {chairData && <Outrounds data={chairData} />}
          </>
          }
        />
        <Route path="/calculator" element=
          {<>

            <DisclaimerNotice />
            {defJson && <Calculator data={byAdjudicators} competitions={defJson} />}
          </>
          }
        />
        <Route path="/debatekeepergen" element=
          {
            <DebateTimekeeperXMLGenerator />
          } />
        <Route path="/rooms" element=
          {<>
            {<RoomCounter />}
          </>
          }
        />
        <Route path="/tabs" element={<Tabs />} />
        <Route path="/tab" element={<SeasonList seasons={tabs} />} />
        <Route path="/judgesCountByRank" element=
          {<>
            <DisclaimerNotice />
            {defJson && <JudgesCountByRank data={byAdjudicators} competitions={defJson} clubAssociations={clubAssociations} />}
          </>
          }
        />
        <Route path="/judgesTimeline" element=
          {<>
            <DisclaimerNotice />
            {defJson && <JudgesTimeline data={byAdjudicators} competitions={defJson} />}
          </>}
        />
        <Route path="/adjudicators" element=
          {<>
            <DisclaimerNotice />
            {defJson && <Judges data={byAdjudicators} competitions={defJson} clubAssociations={clubAssociations} verbose={false} />}
          </>}
        />
        <Route path="/motion" element={<RandomMotion />} />
        <Route path="/" element={<RandomMotion />} />
      </Routes>
    </div >
  );
};
export default Home;
