let order = ["SC", "C2", "C1", "W3", "W2", "W1"];


module.exports = {
    legacyGetByAdjudicators: (comps, newRanks) => {
        return legacyGetByAdjudicators(comps, newRanks)
    }
};
function legacyGetByAdjudicators(comps, newRanks) {
    let judges = {}
    for (let year in comps) {
        for (let comp in comps[year]) {
            let data = comps[year][comp]
            newAddPos(comp, data, "ca", judges, year)
            newAddPos(comp, data, "dca", judges, year)
            newAddPos(comp, data, "aca", judges, year)
            addTrainees(comp, data, judges, year)
            addWings(comp, data, judges, year)
            addInroundChairs(comp, data, judges, year)
            newAddExtraBreaks(comp, data, judges, year)
            data.outrounds.forEach(or => {
                newAddRound(comp, data, judges, year, or)
            })
        }
    }

    let ordered = Object.keys(judges).sort().reduce(
        (obj, key) => {
            obj[key] = judges[key]
            return obj
        }, {}
    );
    addRanks(ordered, newRanks)
    addBreaksCount(ordered)
    addAteamCount(comps, ordered)
    ordered = Object.entries(ordered).sort((a, b) => {
        return b[1].breaks - a[1].breaks
    }).reduce(
        (obj, val) => {
            obj[val[0]] = val[1]
            return obj
        }, {}
    )
    ordered = Object.entries(ordered).sort((a, b) => {
        return order.indexOf(a[1].rank) - order.indexOf(b[1].rank);
    }).reduce(
        (obj, val) => {
            obj[val[0]] = val[1]
            return obj
        }, {}
    )
    return ordered
}

function addAteamCount(comps, judges) {
    let years = Object.keys(comps)
    let lastYear = years[years.length - 1]
    for (let judge in judges) {
        judges[judge].ateam = Object.values(judges[judge].competitions).filter(x => x.ca || x.dca || x.aca).length
        judges[judge].ateamCurrentYear = Object.values(judges[judge].competitions).filter(x => {
            return x.year == lastYear && (x.ca || x.dca || x.aca)
        }).length
    }
}

function addBreaksCount(judges) {
    for (let judge in judges) {
        let comps = Object.values(judges[judge].competitions)
        let breaks = comps.filter(x => x.didBreak).length
        let breaksNoForeigns = comps.filter(x => x.didBreak && !x.isForeign).length
        breaks += comps.some(x => x.didBreak && x.doubleBreak) ? 1 : 0
        breaksNoForeigns += comps.some(x => x.didBreak && x.doubleBreak && !x.isForeign) ? 1 : 0
        judges[judge].breaks = breaks
        judges[judge].breaksNoForeigns = breaksNoForeigns
    }
}

function addRanks(judges, newRanks) {
    for (let judge in judges) {
        judges[judge].rankCriteria = newRanks ? getNewJudgeRankCriteria(judges, judge) : getJudgeRankCriteria(judges, judge)
        judges[judge].rankCriteriaNoForeigns = newRanks ? getNewJudgeRankCriteria(judges, judge, true) : getJudgeRankCriteria(judges, judge, true)
        judges[judge].rank = getJudgeRank(judges[judge].rankCriteria)
        judges[judge].rankNoForeigns = getJudgeRank(judges[judge].rankCriteriaNoForeigns)
    }
}

function getJudgeRank(criteria) {
    if (criteria["SC"].some(x => x)) {
        return "SC"
    } else if (criteria["C2"].some(x => x)) {
        return "C2"
    } else if (criteria["C1"].some(x => x)) {
        return "C1"
    } else if (criteria["W3"].some(x => x)) {
        return "W3"
    } else if (criteria["W2"].some(x => x)) {
        return "W2"
    } else {
        return "W1"
    }
}

function getJudgeRankCriteria(judges, judge, ignoreForeigns) {
    let data = Object.values(judges[judge].competitions)
    if (ignoreForeigns) {
        data = data.filter(comp => !comp.isForeign)
    }
    let rankCriteria = {}
    rankCriteria["SC"] = [isSCFirstCriteria(data), isSCSecondCriteria(data), isSCThirdCriteria(data)]
    rankCriteria["C2"] = [isC2FirstOption(data), isC2SecondOption(data)]
    rankCriteria["C1"] = [isC1(data)]
    rankCriteria["W3"] = [isW3(data)]
    rankCriteria["W2"] = [isW2(data)]
    rankCriteria["W1"] = [true]
    return rankCriteria
}


function getNewJudgeRankCriteria(judges, judge, ignoreForeigns) {
    let data = Object.values(judges[judge].competitions)
    if (ignoreForeigns) {
        data = data.filter(comp => !comp.isForeign)
    }
    let rankCriteria = {}
    rankCriteria["SC"] = [isSCFirstCriteria(data), isSCNewSecondCriteria(data), isSCNewThirdCriteria(data)]
    rankCriteria["C2"] = [isC2NewOption(data)]
    rankCriteria["C1"] = isNewC1(data)
    rankCriteria["W3"] = isNewW3(data)
    rankCriteria["W2"] = isNewW2(data)
    rankCriteria["W1"] = [true]
    return rankCriteria
}

function isC2NewOption(data) {
    return data.filter(comp => {
        let isAteam = comp.ca || comp.dca || comp.aca
        return comp.outroundChair && !isAteam
    }).length >= 2
}

function isSCFirstCriteria(data) {
    return data.some(comp => {
        let isAteam = comp.ca || comp.dca || comp.aca
        return isAteam && comp.isWEUDC
    })
}

function isSCSecondCriteria(data) {
    return data.some(comp => {
        return comp.outroundChair && comp.isWEUDC
    })
}

function isSCThirdCriteria(data) {
    return data.filter(comp => {
        let isAteam = comp.ca || comp.dca || comp.aca
        return comp.outroundChair && comp.isOfficial && !isAteam
    }).length >= 3 && isC2FirstOption(data) && isC2SecondOption(data)
}

function isSCNewSecondCriteria(data) {
    return data.some(comp => {
        let majorOutroundChair = comp.outroundChair && comp.isWEUDC
        let majorFinalsOutroundWing = comp.isWEUDC && comp.outroundPanels
            .some(panel => (panel.category == "open" || panel.category == "esl") &&
                panel.type == "GF" &&
                panel.position == "wing")
        return majorOutroundChair || majorFinalsOutroundWing
    })
}

function isSCNewThirdCriteria(data) {
    return data.filter(comp => {
        let isAteam = comp.ca || comp.dca || comp.aca
        return comp.outroundChair && comp.isOfficial && !isAteam
    }).length >= 1 && isC2FirstOption(data)
}

function isC1(data) {
    return getBreaksCount(data, true) >= 3
}

function isNewC1(data) {
    let breaksNoAca = data.filter(comp => {
        return comp.didBreak && !comp.aca
    }).length
    let didDoubleBreakNoAca = data.some(comp => {
        return comp.didBreak && comp.doubleBreak && !comp.aca
    })
    let breakCriteria = breaksNoAca + (didDoubleBreakNoAca ? 1 : 0) >= 4
    let inroundChairCriteria = data.filter(comp => {
        return comp.inroundChair
    }).count >= 4
    let outroundChairCriteria = data.filter(comp => {
        return comp.outroundChair && !comp.ca && !comp.dca && !comp.aca
    })
    let option1 = breakCriteria && inroundChairCriteria
    let option2 = breakCriteria && outroundChairCriteria
    let option3 = data.some(comp => {
        return comp.didBreak && comp.isWEUDC
    })
    return [option1, option2, option3]
}

function isW3(data) {
    return data.filter(comp => {
        return comp.didBreak || comp.inroundChair
    }).length >= 2
}

function isNewW3(data) {
    let option1 = data.filter(comp => {
        return comp.inroundChair
    }).length >= 4
    let option2 = data.filter(comp => {
        return comp.didBreak && !comp.aca
    }).length >= 2
    let option3breaks = data.filter(comp => {
        return (comp.didBreak) && !comp.aca
    }).length >= 1
    let option3chairs = data.filter(comp => {
        return (!comp.didBreak && comp.inroundChair)
    }).length >= 2
    return [option1, option2, option3breaks && option3chairs]
}

function isW2(data) {
    return data.map(comp => {
        return Math.floor(comp.inroundCount / 3)
    }).reduce((a, b) => a + b, 0) >= 4
}

function isNewW2(data) {
    let option1 = data.filter(comp => {
        return comp.inroundChair
    }).length >= 1
    let option2 = data.filter(comp => {
        return comp.didBreak && !comp.aca
    }).length >= 1
    let option3 = data.map(comp => {
        return Math.floor(comp.inroundCount / 3)
    }).reduce((a, b) => a + b, 0) >= 4
    return [option1, option2, option3]
}

function isC2FirstOption(data) {
    return data.some(comp => {
        return comp.didBreak && comp.isWEUDC
    })
}

function getBreaksCount(data, includeDoubleBreak) {
    let count = data.filter(comp => {
        return comp.didBreak
    }).length
    let didDoubleBreak = data.some(comp => {
        return comp.didBreak && comp.doubleBreak
    })
    return count + (includeDoubleBreak && didDoubleBreak ? 1 : 0)
}

function isC2SecondOption(data) {
    return getBreaksCount(data, true) >= 8
}

function addTrainees(comp, data, judges, year) {
    baseAddComp(comp, data, data.inroundTrainees, judges, year, (_) => { })
}

function addWings(comp, data, judges, year) {
    baseAddComp(comp, data, data.inroundWings, judges, year, (_) => { })
}

function addInroundChairs(comp, data, judges, year) {
    baseAddComp(comp, data, data.inroundChairs, judges, year, (currComp) => {
        currComp.inroundChair = true;
    })
}

function baseAddComp(comp, data, arr, judges, year, updateCallback) {
    arr.forEach(judge => {
        judges[judge] = judges[judge] || {}
        checkYear(judges, judge, year)
        judges[judge].competitions = judges[judge].competitions || {}
        judges[judge].competitions[comp] = judges[judge].competitions[comp] || getCompTemplate(data, year)
        updateCallback(judges[judge].competitions[comp])
    })
}

function newAddPos(comp, data, pos, judges, year) {
    baseAddComp(comp, data, data[pos], judges, year, (currComp) => {
        currComp[pos] = true
    })
}

function newAddRound(comp, data, judges, year, or) {
    baseAddComp(comp, data, or.outroundChair, judges, year, (currComp) => {
        currComp.didBreak = true
        currComp.inroundChair = true
        currComp.outroundChair = true
        currComp.outroundPanels.push({
            "type": or.type,
            "category": or.category,
            "position": "chair"
        })
    })
    baseAddComp(comp, data, or.outroundWing, judges, year, (currComp) => {
        currComp.didBreak = true
        currComp.outroundPanels.push({
            "type": or.type,
            "category": or.category,
            "position": "wing"
        })
    })
    baseAddComp(comp, data, or.outroundTrainee, judges, year, (currComp) => {
        currComp.breakTrainee = true
        currComp.outroundPanels.push({
            "type": or.type,
            "category": or.category,
            "position": "trainee"
        })
    })
}


function newAddExtraBreaks(comp, data, judges, year) {
    baseAddComp(comp, data, data.extraBreakingAdjudicators, judges, year, (currComp) => {
        currComp.didBreak = true
    })
}

function getCompTemplate(data, year) {
    return {
        "doubleBreak": data.doubleBreak,
        "year": year,
        "isWEUDC": data.isWEUDC,
        "inroundCount": data.inroundCount,
        "isOfficial": data.isOfficial,
        "inroundChair": false,
        "isForeign": data.isForeign && !data.isWEUDC,
        "outroundChair": false,
        "didBreak": false,
        "ca": false,
        "dca": false,
        "aca": false,
        "breakTrainee": false,
        "outroundPanels": []
    };
}

function checkYear(judges, judge, year) {
    if ((judges[judge].minYear || "") < year) judges[judge].minYear = year
}
