import { Button, TableCell } from '@mui/material';
import styled from 'styled-components';

export interface ContainerProps {
}

export const Container = styled.div<ContainerProps>`
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    &.horizontal {
        flex-direction: row;
    }
`;

export const Text = styled.div`
`;

export const RTableCell = styled(TableCell)`
    && {
        text-align: right;
    }
`;