import React, { useEffect, useState } from 'react';
import { Container, Section } from './styles';
import { FormControl, MenuItem, Select, TextField, Typography, Autocomplete, Alert } from '@mui/material';
import { Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const DoubleBreak = 0
const Official = 1
const WEUDC = 2
const Israeli = 3
const Foreign = 4

const Hebrew = 0
const English = 1

export const AddCompetition = ({
  ...props
}) => {
  const [tabUrl, setTabUrl] = useState("")
  const [ateam, setAteam] = useState("")
  const [compType, setCompType] = useState(DoubleBreak)
  const [output, setOutput] = useState(null)
  const [translations, setTranslations] = useState(null)
  const [language, setLanguage] = useState(Hebrew)
  const [isInvalidInput, setIsInvalidInput] = useState(true)
  const [selectedDate, setSelectedDate] = useState(format(new Date(), 'yyyy-MM-dd'));

  useEffect(() => {
    if (!tabUrl) {
      setIsInvalidInput(true)
    } else if (!ateam) {
      setIsInvalidInput(true)
    } else {
      setIsInvalidInput(false)
    }
  }, [tabUrl, ateam])

  const loadNamesClicked = async (_) => {
    try {
      let res = await fetch("/api/load_names_from_tabbycat", {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({ url: tabUrl })
      })
      if (!res.ok) {
        console.log(res)
        return
      }
      let out = await res.json()
      let namesMap = {}
      for (let i in out) {
        namesMap[out[i]] = ""
      }
      setTranslations(namesMap)
    } catch (e) {
      console.log(e)
    }
  }

  const updateTranslations = (key, val) => {
    let map = translations
    map[key] = val
    setTranslations(map)
  }

  const uploadClicked = async (_) => {
    await postAndSetOutput("/api/add_competition_data_from_tabbycat")
  }

  const uploadMotionsClicked = async (_) => {
    await postAndSetOutput("/api/add_motions_from_tabbycat")
  }

  const postAndSetOutput = async (endpoint) => {
    try {
      let response = await postData(endpoint);
      setOutput({ severity: "success", response })
    } catch (e) {
      setOutput({ severity: "error", response: e.toString() })
    }
  }
  
  const postData = async (endpoint) => {
    let ateamMembers = ateam.split(/,\s+/)
    try {
      let res = await fetch(endpoint, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({ url: tabUrl, ateam: ateamMembers, translations, type: compType, date: selectedDate })
      })
      if (!res.ok) throw new Error("Failed to upload", res.text())
      return await res.json()
    } catch (e) {
      console.log(e)
    }
  }
  
  const handleDateChange = (date) => {
    if (date && !isNaN(date)) {
      const formattedDate = format(date, 'yyyy-MM-dd');
      setSelectedDate(formattedDate);
    } else {
      setSelectedDate('');
    }
  };  

  return (
    <Container>
      {output && <Alert variant="filled" severity={output.severity}>הפעולה בוצעה בהצלחה</Alert>}
      <Section>
        <Typography>כתובת הטאב, למשל<br />https://idltournaments.calicotab.com/newbies2023/</Typography>
        <TextField fullWidth value={tabUrl} onChange={(e) => { setTabUrl(e.target.value) }} />
      </Section>

      <Section>
        <Typography>מחרוזת חברי האייטים <a href="https://docs.google.com/spreadsheets/d/1tB0CZlnBgG9CA15IXy-kh6yg0G7EapHG-xhR6J7DP84/edit?usp=sharing">מטבלת התחרויות</a>
        </Typography>
        <TextField fullWidth value={ateam} onChange={(e) => { setAteam(e.target.value) }} />
      </Section>

      <Section style={{direction: 'rtl'}}>
        <Typography>תאריך התחרות</Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Date"
            format='dd/MM/yyyy'
            value={selectedDate ? new Date(selectedDate) : null}
            onChange={handleDateChange}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Section>

      <Section>
        <Typography>סוג התחרות</Typography>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={compType}
            onChange={(e) => setCompType(e.target.value)}>
            <MenuItem value={DoubleBreak}>{"אליפות הארץ/הג'וניורים (תחרות רשמית עם דאבל ברייק)"}</MenuItem>
            <MenuItem value={Official}>{"האליפות הפתוחה/אינגליש קאפ/אינגליש אופן (תחרות רשמית בלי דאבל ברייק)"}</MenuItem>
            <MenuItem value={WEUDC}>{"אליפות אירופה/העולם"}</MenuItem>
            <MenuItem value={Israeli}>{"תחרות בליגה הישראלית שאינה רשמית"}</MenuItem>
            <MenuItem value={Foreign}>{"תחרות בחו\"ל שאינה אליפות אירופה/העולם"}</MenuItem>
          </Select>
        </FormControl>
      </Section>

      <Section>
        <Typography>שפת השמות בטאב</Typography>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}>
            <MenuItem value={Hebrew}>{"עברית"}</MenuItem>
            <MenuItem value={English}>{"אנגלית"}</MenuItem>
          </Select>
        </FormControl>
      </Section>

      {(language == English && !translations) && <Button disabled={isInvalidInput} onClick={loadNamesClicked}>טען רשימת שמות לתרגום</Button>}
      {(language == English && translations) && Object.entries(translations).map(([english, hebrew]) => (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <TextField style={{ width: '200px' }} onChange={(e) => { updateTranslations(english, e.target.value) }} />
          <Typography style={{ width: '200px' }}>{english}</Typography>
        </div>
      ))}
      {(language == Hebrew || translations) && <Button disabled={isInvalidInput} onClick={uploadClicked}>העלאת טאב</Button>}
      {<Button disabled={isInvalidInput} onClick={uploadMotionsClicked}>העלאת מושנים</Button>}
    </Container>
  );
};