import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";


const RoundTab = ({ tab, roundIndex }) => {
  const [roundTab, setRoundTab] = useState(null);

  useEffect(() => {
    if (!tab) return
    let roundTab = []
    let isElimination = tab.round[roundIndex]._attributes.elimination == "true"
    let debates = [].concat(tab.round[roundIndex].debate)
    debates.forEach(debate => {
      if (debate && debate.side) {
        let room = getRoomName(debate)
        let og = getSide(debate, isElimination, 0)
        let oo = getSide(debate, isElimination, 1)
        let cg = getSide(debate, isElimination, 2)
        let co = getSide(debate, isElimination, 3)
        let adjudicators = getAdjudicators(debate)
        roundTab.push({ room, og, oo, cg, co, adjudicators })
      }
    })
    setRoundTab(roundTab)
  }, [tab])

  const getRoomName = (debate) => {
    let venue = tab.venue.find(venue => venue._attributes.id == debate._attributes.venue)
    let venueName = ""
    if (venue) {
      venueName = venue._text
    }
    return venueName
  }

  const getSide = (debate, isElimination, index) => {
    let rankEmoji = getPositionEmoji(debate, isElimination, index)
    let team = tab.participants.team.find(team => team._attributes.id == debate.side[index]._attributes.team)
    let teamName = ""
    if (team) {
      teamName = team._attributes.name
    }
    return `${rankEmoji} ${teamName}`
  }

  const getPositionEmoji = (debate, isElimination, index) => {
    if (isElimination) {
      switch (debate.side[index].ballot._attributes.rank) {
        case "1":
          return "⬆️"
        default:
          return "⬇️"
      }
    } else {
      switch (debate.side[index].ballot._attributes.rank) {
        case "1":
          return "⬆️"
        case "2":
          return "↗️"
        case "3":
          return "↘️"
        default:
          return "⬇️"
      }
    }
  }

  const getPositionText = (debate, isElimination, index) => {
    if (debate)
      if (isElimination) {
        switch (debate.side[index].ballot._attributes.rank) {
          case "1":
            return "advanced"
          default:
            return "eliminated"
        }
      } else {
        switch (debate.side[index].ballot._attributes.rank) {
          case "1":
            return "1st"
          case "2":
            return "2nd"
          case "3":
            return "3rd"
          default:
            return "4th"
        }
      }
  }

  const getAdjudicators = (debate) => {
    let chairId = debate._attributes.chair
    let wingsIds = debate._attributes.adjudicators.split(" ").filter(id => id != chairId)
    let chair = tab.participants.adjudicator.find(adjudicator => adjudicator._attributes.id == chairId)
    let chairName = ""
    if (chair) {
      chairName = `${chair._attributes.name} ©`
    }
    let wingNames = tab.participants.adjudicator.filter(adjudicator => wingsIds.includes(adjudicator._attributes.id))
      .map(wing => wing._attributes.name)
    return [chairName, ...wingNames].join(", ")
  }

  return (
    <div style={{ marginTop: '30px' }}>
      <Typography variant="h6" component="div" gutterBottom>
        {tab.round[roundIndex]._attributes.name}
      </Typography>
      <TableContainer style={{ direction: 'ltr' }} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell >Room</TableCell>
              <TableCell >OG</TableCell>
              <TableCell >OO</TableCell>
              <TableCell >CG</TableCell>
              <TableCell >CO</TableCell>
              <TableCell >Adjudicators</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roundTab && roundTab.map((team, i) => (
              <TableRow key={i}>
                <TableCell >{team.room}</TableCell>
                <TableCell >{team.og}</TableCell>
                <TableCell >{team.oo}</TableCell>
                <TableCell >{team.cg}</TableCell>
                <TableCell >{team.co}</TableCell>
                <TableCell >{team.adjudicators}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RoundTab;
