import React from "react";
import { categoryDic, typeDic } from "../constants";

const CompetitionOutrounds = ({ outrounds }) => {

    function getSubtitle(round) {
        return `${typeDic[round.type]} ${categoryDic[round.category]}`
    }

    const singleListRenderer = (round) => {
        return (<>
            <b>{getSubtitle(round)}</b>
            <br />
            {getPanel(round)}
            <br />
            <br />
        </>)
    }

    const getPanel = (round) => {
        let chairList = round.outroundChair.map(chair => `${chair} (C)`)
        let traineeList = round.outroundTrainee.map(trainee => `${trainee} (T)`)

        return `פאנל: ${chairList.concat(round.outroundWing, traineeList).join(", ")}`
    }

    return (
        <div>
            {outrounds.map(outround => singleListRenderer(outround))}
        </div>
    )
}

export default CompetitionOutrounds;