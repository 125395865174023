import React, { useEffect } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { sleep } from "./constants";

export default function PracticeList({ }) {
  const [data, setData] = React.useState(null);


  React.useEffect(() => {
    fetchData().catch((error) => { console.error("Unable to fetch data:", error) })
  }, [])

  const fetchData = async () => {
    let res = await fetch("/api/get_practices")
    if (!res.ok) {
      await sleep(500)
      fetchData()
      return
    }
    let data = await res.json()
    setData(Object.values(data.data))
  }

  return (
    <>
      {data && data.length > 0 &&
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right">מועדון</TableCell>
                <TableCell align="right">אימונים קבועים</TableCell>
                <TableCell align="right">אימונים פתוחים באתר</TableCell>
                <TableCell align="right">מיקום</TableCell>
                <TableCell align="right">דרכי התקשרות</TableCell>
                <TableCell align="right">לינק לאתר</TableCell>
                <TableCell align="right">מידע נוסף</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow>
                  <TableCell style={{ whiteSpace: 'pre-wrap' }} align="right">
                    {row.name}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'pre-wrap' }} align="right">
                    {row.schedule}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'pre-wrap' }} align="right">
                    {row.practices && row.practices.map((practice) => (
                      <p>{practice.name}:&#8207; {practice.date} {practice.time}</p>
                    ))}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'pre-wrap' }} align="right">
                    {row.address}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'pre-wrap' }} align="right">
                    {row.contact}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'pre-wrap' }} align="right">
                    {row.website && <a href={row.website}>לינק</a>}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'pre-wrap' }} align="right">
                    {row.info}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
      {data && data.length == 0 &&
        <div style={{ color: 'red', fontSize: '20px' }}>
          בעיה בטעינת האימונים, נסו לרענן את הדף
        </div>}
    </>
  );
}