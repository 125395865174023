import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Alert,
  Box,
  CircularProgress
} from '@mui/material';

const NameReplacementForm = () => {
  const [originalName, setOriginalName] = useState('');
  const [newName, setNewName] = useState('');
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setStatus({ type: '', message: '' });

    // Create FormData object
    const formData = new FormData();
    formData.append('originalName', originalName);
    formData.append('newName', newName);

    try {
      const response = await fetch('/api/replace_name', {
        method: 'POST',
        body: formData
      });

      const data = await response.json();

      if (response.ok) {
        setStatus({
          type: 'success',
          message: 'Name successfully replaced!'
        });
        // Clear the form
        setOriginalName('');
        setNewName('');
      } else {
        setStatus({
          type: 'error',
          message: data.message || 'An error occurred while replacing the name.'
        });
      }
    } catch (error) {
      setStatus({
        type: 'error',
        message: 'Failed to connect to the server.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card sx={{ maxWidth: 400, margin: 'auto', mt: 4 }}>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Replace Name
        </Typography>
        
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Original Name"
              value={originalName}
              onChange={(e) => setOriginalName(e.target.value)}
              required
              fullWidth
              variant="outlined"
              disabled={isLoading}
            />

            <TextField
              label="New Name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              required
              fullWidth
              variant="outlined"
              disabled={isLoading}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
              sx={{ mt: 1 }}
            >
              {isLoading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CircularProgress size={20} color="inherit" />
                  Processing...
                </Box>
              ) : (
                'Replace Name'
              )}
            </Button>

            {status.message && (
              <Alert 
                severity={status.type === 'error' ? 'error' : 'success'}
                sx={{ mt: 2 }}
              >
                {status.message}
              </Alert>
            )}
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default NameReplacementForm;