import React from 'react';
import * as ReactBootStrap from "react-bootstrap";
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";

const NavBar = () => {
  return (
    <div className="App">
      <ReactBootStrap.Navbar collapseOnSelect expand="xl" bg="danger" variant="dark">
        <ReactBootStrap.Navbar.Brand>DebateLand</ReactBootStrap.Navbar.Brand>
        <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
          <ReactBootStrap.Nav className="mr-auto">
          <Link to="/">
              <ReactBootStrap.Nav.Link href="/">הגרלת מושנים</ReactBootStrap.Nav.Link>
            </Link>
            <Link to="/adjudicators">
              <ReactBootStrap.Nav.Link href="/adjudicators">הישגים לפי שופט</ReactBootStrap.Nav.Link>
            </Link>
            <Link to="/competitions">
              <ReactBootStrap.Nav.Link href="/competitions">הישגים לפי תחרות</ReactBootStrap.Nav.Link>
            </Link>
            <Link to="/outrounds">
              <ReactBootStrap.Nav.Link href="/outrounds">סטטיסטיקות אאוטראונדס</ReactBootStrap.Nav.Link>
            </Link>
            <Link to="/practices">
              <ReactBootStrap.Nav.Link href="/practices">לוח אימונים</ReactBootStrap.Nav.Link>
            </Link>
            <Link to="/calculator">
              <ReactBootStrap.Nav.Link href="/calculator">מחשבון קאפ</ReactBootStrap.Nav.Link>
            </Link>
            <Link to="/debatekeepergen">
              <ReactBootStrap.Nav.Link href="/debatekeepergen">גנרטור XML</ReactBootStrap.Nav.Link>
            </Link>
            <Link to="/rooms">
              <ReactBootStrap.Nav.Link href="/rooms">כמות חדרים</ReactBootStrap.Nav.Link>
            </Link>
            <Link to="/tab">
              <ReactBootStrap.Nav.Link href="/tab">טאבים</ReactBootStrap.Nav.Link>
            </Link>
          </ReactBootStrap.Nav>
        </ReactBootStrap.Navbar.Collapse>
      </ReactBootStrap.Navbar>
    </div>
  )
}

export default NavBar;
