import React from "react";
import { Table, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, MenuItem } from "@mui/material";
import { defaultYear } from "./constants";

const clubs = ["RRIS", "בן גוריון", "בר אילן", "המכללה למנהל", "העברית", "הפתוחה", "וייצמן", "חיפה", "טכניון", "עצמאי.ת", "רייכמן", "תל אביב", "בראודה"]

export default function JudgesCountByRank({
  data,
  competitions,
  clubAssociations
}) {
  const [year, setYear] = React.useState(defaultYear)

  const adjs = Object.entries(filterObj(data, x => x.minYear >= year))
  console.log(adjs)
  const adjsPerClub = clubs.reduce((acc, curr) => {
    acc[curr] = {
      w1: getAdjCountForRank("W1", adjs, curr),
      w2: getAdjCountForRank("W2", adjs, curr),
      w3: getAdjCountForRank("W3", adjs, curr),
      c1: getAdjCountForRank("C1", adjs, curr),
      c2: getAdjCountForRank("C2", adjs, curr),
      sc: getAdjCountForRank("SC", adjs, curr),
      total: getAdjCount(adjs, curr, _ => true),
    }
    return acc
  }, {})

  function getAdjCountForRank(rank, adjs, clubName) {
    return getAdjCount(adjs, clubName, x => x[1].rank == rank)
  }

  function getAdjCount(adjs, clubName, filterFunction) {
    let res = adjs.filter(filterFunction).reduce((acc, curr) => {
      if ((clubAssociations[curr[0]] || []).includes(clubName)) {
        return acc + (1.0 / clubAssociations[curr[0]].length)
      } else {
        return acc
      }
    }, 0)
    return Math.round(res * 100) / 100
  }

  return (
    <>
      הסתירו שופטים שלא נרשמה עבורם פעילות מאז
      <Select
        InputProps={{
          disableUnderline: true,
        }}

        id="json-url"
        label="Enter JSON url"
        defaultValue={defaultYear}
        onChange={(e) => setYear(e.target.value)}
      >
        {Object.keys(competitions).map((year) => (
          <MenuItem value={year}>{year}</MenuItem>
        )
        )}
      </Select>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '100vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="right">מועדון</TableCell>
                <TableCell align="right">W1</TableCell>
                <TableCell align="right">W2</TableCell>
                <TableCell align="right">W3</TableCell>
                <TableCell align="right">C1</TableCell>
                <TableCell align="right">C2</TableCell>
                <TableCell align="right">SC</TableCell>
                <TableCell align="right">סה"כ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(adjsPerClub).map((row) => (
                <TableRow key={row[0]}>
                  <TableCell align="right">
                    {row[0]}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].w1}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].w2}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].w3}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].c1}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].c2}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].sc}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].total}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
function filterObj(obj, predicate) {
  let result = {}, key;

  for (key in obj) {
    if (obj.hasOwnProperty(key) && predicate(obj[key])) {
      result[key] = obj[key];
    }
  }

  return result;
};