import styled from 'styled-components';

export interface ContainerProps {
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    width: 40vw;
    align-items: center;
    margin: auto;
    gap: 30px;
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
    margin: auto;
    &.right-align {
        align-items: start;
    }
`;

export const HorizontalSection = styled.div`
display: flex;
flex-direction: row;
`