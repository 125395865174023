import { Autocomplete, Stack, Select, Button, Checkbox, FormControl, FormControlLabel, Input, InputLabel, MenuItem, TextField } from "@mui/material";
import React from "react";
import { categoryDic, roleDic, typeDic } from "../constants";

export default function AddNewBreak({ judges, competitions }) {
  const [name, setName] = React.useState("")
  const [comp, setComp] = React.useState("")
  const [year, setYear] = React.useState("")
  const [ca, setCA] = React.useState(false)
  const [dca, setDCA] = React.useState(false)
  const [aca, setACA] = React.useState(false)
  const [chair, setChair] = React.useState(false)
  const [outrounds, setOutrounds] = React.useState([])

  const handleChange = (e) => {
    if (outrounds.length < e.target.value) {
      addNewBreaks(e.target.value - outrounds.length)
    } else if (outrounds.length > e.target.value) {
      removeBreaks(outrounds.length - e.target.value)
    }
  };

  const flattenCompetitions = (competitions) => {
    let comps = {}
    for (let k in competitions) {
      comps = { ...comps, ...competitions[k] }
    }
    return comps
  };

  const changeProp = (index, value, prop, propDic) => {
    const tempBreaks = [...outrounds]
    if (Object.values(propDic).includes(value)) {
      tempBreaks[index][prop] = Object.entries(propDic).filter(x => x[1] == value)[0][0]
    } else {
      tempBreaks[index][prop] = "N/A"
    }
    setOutrounds(tempBreaks)
  };

  const addNewBreaks = (count) => {
    let template = {
      type: "N/A",
      category: "N/A",
      role: "N/A"
    }
    let json = []
    for (let i = 0; i < count; i++) {
      json.push(JSON.parse(JSON.stringify(template)))
    }
    setOutrounds(prevBreaks => [...prevBreaks, ...json]);
  };

  const removeBreaks = (count) => {
    setOutrounds(prevBreaks => {
      return [...prevBreaks.slice(0, -count)]
    });
  };

  const sendMail = () => {
    let competition = `${comp.replace(year, "").trim()} ${year}`
    let json = {
      name,
      competition,
      year,
      ca,
      dca,
      aca,
      chair,
      outrounds
    }
    fetch("/api/addentry/",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(json)
      })
      .then(res => res.json())
      .then(res => { alert("המידע נשלח לבדיקה כדי לבדוק שלא יווצרו כפילויות ובעיות, ויוזן בקרוב. תודה!") })
      .catch(function (res) { alert("הייתה בעיה, נא ליצור קשר עם עידו") })
  }

  return (
    <div className="table" style={{ marginTop: "20px", alignItems: "center", alignContent: "center", textAlign: "center" }}>
      <Stack spacing={2} sx={{
        display: "flex",
        alignItems: "center"
      }}>
        <form noValidate>
          <Autocomplete
            freeSolo
            id="name"
            options={Object.keys(judges)}
            sx={{ width: "300px" }}
            onInputChange={(event, val) => setName(val)}
            renderInput={(params) => <TextField {...params} label={"שם מלא"} />} />
        </form>
        <form noValidate>
          <Autocomplete
            freeSolo
            id="name"
            options={Object.keys(flattenCompetitions(competitions))}
            sx={{ width: "300px" }}
            onInputChange={(event, val) => {
              setComp(val)
              let yr = val.slice(-4)
              if(yr in competitions) {
                setYear(yr)
              }
            }}
            renderInput={(params) => <TextField {...params} label={"שם התחרות"} />} />
        </form>
        <form noValidate>
          <Autocomplete
            freeSolo
            id="name"
            value={year}
            options={Object.keys(competitions)}
            sx={{ width: "300px" }}
            onInputChange={(event, val) => setYear(val)}
            renderInput={(params) => <TextField {...params} label={"שנה"} />} />
        </form>
        <FormControlLabel
          control={<Checkbox onChange={(e) => setCA(e.target.checked)} />}
          label="האם הייתם CA?"
        />
        <FormControlLabel
          control={<Checkbox onChange={(e) => setDCA(e.target.checked)} />}
          label="האם הייתם DCA?"
        />
        <FormControlLabel
          control={<Checkbox onChange={(e) => setACA(e.target.checked)} />}
          label="האם הייתם ACA?"
        />
        <FormControlLabel
          control={<Checkbox onChange={(e) => setChair(e.target.checked)} />}
          label="האם הייתם צ׳רים בסבב פנימי כלשהו?"
        />
        <FormControl style={{ width: "200px" }}>
          <InputLabel id="demo-simple-select-label">בכמה אאוטראונדס שפטת?</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            defaultValue={0}
            onChange={handleChange}>
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
          </Select>
        </FormControl>

        {outrounds.map((item, index) => (
          <Stack direction="row" sx={{
            display: "flex",
            alignItems: "center"
          }}>
            <Autocomplete
              disablePortal
              data-id={index}
              id={"" + index}
              options={["לא זוכר/ת"].concat(Object.values(roleDic))}
              sx={{ width: "200px" }}
              defaultValue="לא זוכר/ת"
              onInputChange={(event, val) => changeProp(index, val, "role", roleDic)}
              renderInput={(params) => <TextField {...params} label={"תפקיד באאוטראונד " + (index + 1)} />} />
            <Autocomplete
              disablePortal
              data-id={index}
              id={"" + index}
              options={["לא זוכר/ת"].concat(Object.values(typeDic))}
              sx={{ width: "200px" }}
              defaultValue="לא זוכר/ת"
              onInputChange={(event, val) => changeProp(index, val, "type", typeDic)}
              renderInput={(params) => <TextField {...params} label={"סוג אאוטראונד " + (index + 1)} />} />
            <Autocomplete
              disablePortal
              data-id={index}
              id={"" + index}
              options={["לא זוכר/ת"].concat(Object.values(categoryDic))}
              sx={{ width: "200px" }}
              defaultValue="לא זוכר/ת"
              onInputChange={(event, val) => changeProp(index, val, "category", categoryDic)}
              renderInput={(params) => <TextField {...params} label={"קטגוריית אאוטראונד " + (index + 1)} />} />
          </Stack>
        ))}
        <Button
          variant="contained"
          onClick={sendMail}>
          שלחו דיווח
        </Button>
      </Stack>
    </div>
  );
}

function InputWithLabel({ label, onInput }) {
  return (
    <div>
      <InputLabel htmlFor={label}>{label}</InputLabel>
      <Input style={{ width: 200 }} id={label} onInput={onInput} />
    </div>
  )
}