import React from "react";
import { CustomList } from "./styles";

const ListRenderer = ({ list }) => {
    return (
        <CustomList>
            {list.map(item => (<li>{item}</li>))}
        </CustomList>
    )
}

export default ListRenderer;