export const runScript = (competitions) => {
  // removeStuff()
}

const addIsForeignProperty = (competitions) => {
  for (let yearName in competitions) {
    for (let compName in competitions[yearName]) {
      competitions[yearName][compName]["isForeign"] = false
    }
  }
  console.log(competitions)
}

const removeStuff = () => {
  fetch('tabs.json', {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then(function (response) {
    return response.json();
  }).then(function (myJson) {
    for (let compName in myJson) {
      delete myJson[compName]["question"]
      myJson[compName].participants.adjudicator.forEach(adj => {
        adj._attributes.score = "10.0"
        delete adj.feedback
      });
    }
    console.log(myJson)
  });
}


// participants > adjudicator > _attributes > score
// participants > adjudicator > feedback
// question