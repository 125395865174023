import React from "react";
import ToggleText from "./ToggleText";
import { Button, Popover, Table, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TextField, Autocomplete, List, ListItem, Select, MenuItem } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import RankCriteriaDisplay from "./CriteriaDisplay";
import { categoryDic, defaultYear, roleDic, typeDic } from "./constants";
import DropdownText from "./EditableText";

const options = [
  'בן גוריון', 'RRIS', 'רייכמן', 'תל אביב', 'וייצמן',
  'המכללה למנהל', 'העברית', 'הפתוחה', 'חיפה', 'טכניון',
  'בר אילן', 'בראודה', 'סקוליז', 'עצמאי.ת'
];

function getSubtitle(role) {
  return `${roleDic[role.position]} ${typeDic[role.type]} ${categoryDic[role.category]}`
}

function includesAny(arr, substr) {
  return arr.some(x => x.includes(substr))
}

function isNotable(comp) {
  let data = comp[1]
  return data.ca ||
    data.dca ||
    data.aca ||
    data.inroundChair ||
    data.outroundChair ||
    data.didBreak ||
    data.breakTrainee ||
    data.outroundPanels.length > 0
}

function getUnnotableCompetitions(competitions) {
  return competitions.length > 0 && (<>
    <ListItem style={{ marginTop: "10px" }}>שפט בתחרויות:</ListItem>
    {competitions.map(x => <ListItem>{x[0]}</ListItem>)}
  </>)
}

function getDescription(comp) {
  let name = comp[0]
  let data = comp[1]
  let ca = data.ca ? "CA" : null
  let dca = data.dca ? "DCA" : null
  let aca = data.aca ? "ACA" : null
  let inroundChair = data.inroundChair ? "צ׳ר בתחרות" : null
  let outroundChair = []
  let outroundWing = []
  let outroundTrainee = []
  if (data.outroundPanels.length > 0) {
    data.outroundPanels.forEach(role => {
      if (role.position == "chair") outroundChair.push(`${getSubtitle(role)}`)
      if (role.position == "wing") outroundWing.push(`${getSubtitle(role)}`)
      if (role.position == "trainee") outroundTrainee.push(`${getSubtitle(role)}`)
    })
  } else {
    if (data.outroundChair) outroundChair.push("צ׳ר באאוטראונד")
    if (data.didBreak) outroundWing.push("ברייק שיפוט")
    if (data.breakTrainee) outroundTrainee.push("ברייק טרייני")
  }
  let arr = [ca, dca, aca, outroundChair.join(", "), outroundWing.join(", "), outroundTrainee.join(", "), inroundChair]
    .filter(x => x && x != "")
    .join(", ")
  if (arr == "") arr = "שפט בתחרות"
  return (
    <ListItem>
      <div lang="he" dir="rtl" style={{ direction: "rtl", marginLeft: "5px", textAlign: "right" }}>{name}:&#8207; {arr}</div>
    </ListItem>
  )
}

export default function Judges({
  data,
  competitions,
  clubAssociations
}) {
  const [year, setYear] = React.useState(defaultYear);
  const [nameFilter, setNameFilter] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeCriteria, setActiveCriteria] = React.useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const verbose = searchParams.get("verbose") == "1";
  
  const handlePopoverOpen = (event, rankCriteria) => {
    setAnchorEl(event.currentTarget);
    setActiveCriteria(rankCriteria);
  };

  const onClubAssociationChanged = (association, name) => {
    clubAssociations[name] = association;
    fetch(`/api/change_club_association`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, association })
    })
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setActiveCriteria(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Link to="/addbreak">
        <Button variant="contained" style={{ margin: '20px' }}>חסר הישג/תחרות ששפטתם בה? לחצו כאן להוספה</Button>
      </Link>
      <br />
      הסתירו שופטים שלא נרשמה עבורם פעילות מאז
      <Select
        InputProps={{
          disableUnderline: true,
        }}

        id="json-url"
        label="Enter JSON url"
        defaultValue={defaultYear}
        onChange={(e) => setYear(e.target.value)}
      >
        {Object.keys(competitions).map((year) => (
          <MenuItem value={year}>{year}</MenuItem>
        )
        )}
      </Select>
      <br />
      <Autocomplete
        disablePortal
        freeSolo
        data-id={"judge_search"}
        id={"judge_search"}
        options={[]}
        sx={{ maxWidth: '300px', margin: '20px 0' }}
        onInputChange={(v, _) => {
          setNameFilter(v.target.value || "")
        }}
        renderInput={(params) => <div>
          <TextField {...params} label={"חיפוש שופטים"} />
        </div>}
      />
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '100vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width="12%" align="right">שם</TableCell>
                <TableCell width="12%" align="right">שיוך מועדוני</TableCell>
                <TableCell width="12%" align="right">דרגת שיפוט</TableCell>
                {verbose && <TableCell width="12%" align="right">דרגה בלי חו"ל</TableCell>}
                <TableCell width="12%" align="right">כמות ברייקים (כולל כפול אם יש)</TableCell>
                {verbose && <TableCell width="12%" align="right">כמות ברייקים (כולל כפול אם יש) בלי חו"ל</TableCell>}
                <TableCell width="12%" align="right">כמות אייטים</TableCell>
                <TableCell width="12%" align="right">כמות אייטים בשנה האחרונה</TableCell>
                <TableCell width="40%" align="right">הישגי שיפוט</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object
                .entries(data)
                .filter(x => (nameFilter != "" && (x[0].includes(nameFilter) || includesAny(clubAssociations[x[0]] || [], nameFilter))) || (nameFilter == "" && x[1].minYear >= year))
                  .map((row) => (
                    <TableRow key={row[0]}>
                      <TableCell align="right">
                        {row[0]}
                      </TableCell>
                      <TableCell align="right">
                        <DropdownText initialText={clubAssociations[row[0]]} onComplete={(t) => onClubAssociationChanged(t, row[0]) } options={options} />
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          aria-owns={open ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={e => handlePopoverOpen(e, row[1].rankCriteria)}
                          onMouseLeave={handlePopoverClose}
                        >
                          {row[1].rank}
                        </Typography>
                      </TableCell>
                      {verbose && <TableCell align="right" style={row[1].rank != row[1].rankNoForeigns ? { background: '#FFCCCB' } : {}}>
                        <Typography
                          aria-owns={open ? 'mouse-over-popover' : undefined}
                          aria-haspopup="true"
                          onMouseEnter={e => handlePopoverOpen(e, row[1].rankCriteriaNoForeigns)}
                          onMouseLeave={handlePopoverClose}>
                          {row[1].rankNoForeigns}
                        </Typography>
                      </TableCell>}
                      <TableCell align="right">
                        {row[1].breaks}
                      </TableCell>
                      {verbose && <TableCell align="right" style={row[1].breaks != row[1].breaksNoForeigns ? { background: '#FFCCCB' } : {}}>
                        {row[1].breaksNoForeigns}
                      </TableCell>}
                      <TableCell align="right">
                        {row[1].ateam}
                      </TableCell>
                      <TableCell align="right">
                        {row[1].ateamCurrentYear}
                      </TableCell>
                      <TableCell align="right">
                        <ToggleText
                          content={<List>
                            {
                              Object.entries(row[1].competitions)
                                .filter(x => isNotable(x))
                                .map(comp =>
                                  (getDescription(comp))
                                )
                            }
                            {
                              getUnnotableCompetitions(Object.entries(row[1].competitions)
                                .filter(x => !isNotable(x)))
                            }
                          </List>
                          } />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <RankCriteriaDisplay rankCriteria={activeCriteria} />
      </Popover>
    </>
  );
}