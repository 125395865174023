import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";


const RankCriteriaDisplay = ({ rankCriteria }) => {
  const getCriteria = (rank, criteriaIndex) => {
    return rankCriteria[rank][criteriaIndex] ? "✔️" : "❌"
  }

  const lineStyle = { direction: 'rtl', textAlign: 'right' }

  return (
    (rankCriteria && <TableContainer style={{ direction: 'rtl', textAlign: 'right' }}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>SC</TableCell>
            <TableCell>
              <div style={lineStyle}>{getCriteria("SC", 0)} אייטים ביורוז/וורלדס</div>
              <div style={lineStyle}>{getCriteria("SC", 1)} צ'ר אאוטראונד ביורוז/וורלדס</div>
              <div style={lineStyle}>{getCriteria("SC", 2)} צ'ר אאוטראונד ב-3 רשמיות שלא היה באייטים + כל הקריטריונים של C2</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>C2</TableCell>
            <TableCell>
              <div style={lineStyle}>{getCriteria("C2", 0)} ברייק ביורוז/וורלדס</div>
              <div style={lineStyle}>{getCriteria("C2", 1)} שמונה ברייקים</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>C1</TableCell>
            <TableCell>
              <div style={lineStyle}>{getCriteria("C1", 0)} שלושה ברייקים</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>W3</TableCell>
            <TableCell>
              <div style={lineStyle}>{getCriteria("W3", 0)} ברייק ו/או צ'ר בשתי תחרויות שונות</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>W2</TableCell>
            <TableCell>
              <div style={lineStyle}>{getCriteria("W2", 0)} שיפוט בארבע תחרויות</div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>W1</TableCell>
            <TableCell>
              <div style={lineStyle}>✔️ דרגת הבסיס</div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>)
  );
};

export default RankCriteriaDisplay;
