import React from "react";
import SingleListRenderer from "./SingleListRenderer";

const CompetitionAteam = ({ ca, dca, aca }) => {
    return (
        <div>
            {SingleListRenderer(ca, "CA")}
            {SingleListRenderer(dca, "DCA")}
            {SingleListRenderer(aca, "ACA")}
        </div>
    )
}

export default CompetitionAteam;