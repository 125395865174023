import { Autocomplete, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { ColumnGrid } from './styles';
import Typography from '@mui/material/Typography';

const _defaultCosts = [
  "", "", "", "", "", ""
]

const Calculator = ({
  data,
  competitions,
}) => {
  const [costs, setCosts] = useState(_defaultCosts);
  const [cap, setCap] = useState(null);
  const [capRequirements, setCapRequirements] = useState(null);
  const [maxCapCount, setMaxCapCount] = useState(1);
  const getData = () => {
    fetch('cap.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then(function (response) {
      return response.json();
    }).then(function (myJson) {
      setCap(myJson.cap);
    });
  }

  const SC_DUMMY = "שופט.ת בדרגה SC"
  const C2_DUMMY = "שופט.ת בדרגה C2"
  const C1_DUMMY = "שופט.ת בדרגה C1"
  const W3_DUMMY = "שופט.ת בדרגה W3"
  const W2_DUMMY = "שופט.ת בדרגה W2"
  const W1_DUMMY = "שופט.ת בדרגה W1"
  const DUMMY_LIST = [SC_DUMMY, C2_DUMMY, C1_DUMMY, W3_DUMMY, W2_DUMMY, W1_DUMMY]

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    recalculateCapRequirements();
  }, [cap, costs])

  const handleCostsChange = (index, value) => {
    const _tempCosts = [...costs];
    _tempCosts[index] = value;
    setCosts(_tempCosts);
  };

  const recalculateCapRequirements = () => {
    if (!cap) {
      setCapRequirements(null)
      setMaxCapCount(1)
      return
    }
    let maxCap = 1
    let capReqs = []
    let judges = getJudgesArray(costs)
    for (let i = 0; i < cap.length; i++) {
      capReqs.push([])
      for (let j = 0; j < cap[i].length; j++) {
        capReqs[i].push(isCompatible(cap[i][j], JSON.parse(JSON.stringify(judges))))
        if (capReqs[i][j]) {
          maxCap = i + 1
        }
      }
    }
    setCapRequirements(capReqs)
    setMaxCapCount(maxCap)
  }

  const addNewCost = () => {
    setCosts(prevCosts => [...prevCosts, ""]);
  };

  const removeCost = () => {
    setCosts(prevCosts => {
      if (prevCosts.length <= 1) {
        return [...prevCosts]
      } else {
        return [...prevCosts.slice(0, -1)]
      }
    });
  };

  const getJudgesArray = (judges) => {
    let arr = [0, 0, 0, 0, 0, 0]
    judges.forEach(judge => {
      if (judge && judge in data || DUMMY_LIST.includes(judge)) {
        let rank = getRank(judge)
        let index = 0;
        switch (rank) {
          case "SC":
            index = 0
            break
          case "C2":
            index = 1
            break
          case "C1":
            index = 2
            break
          case "W3":
            index = 3
            break
          case "W2":
            index = 4
            break
          default:
            index = 5
        }
        arr[index]++
      }
    })
    return arr
  }

  const getRank = (name) => {
    if (DUMMY_LIST.includes(name)) {
      switch (name) {
        case SC_DUMMY:
          return "SC"
        case C2_DUMMY:
          return "C2"
        case C1_DUMMY:
          return "C1"
        case W3_DUMMY:
          return "W3"
        case W2_DUMMY:
          return "W2"
        case W1_DUMMY:
          return "W1"


      }
    }
    if (!(name in data)) {
      return "N/A"
    }
    return data[name].rank
  }

  const isCompatible = (req, have) => {
    for (let i = 0; i < req.length; i++) {
      let judges = 0
      for (let j = 0; j <= i && judges < req[i]; j++) {
        while (have[j] > 0 && judges < req[i]) {
          judges++
          have[j]--
        }
      }
      if (judges < req[i]) {
        return false
      }
    }
    return true;
  }

  const hideIfZero = (num) => {
    return num == 0 ? '' : `${num}`
  }

  const getOptions = (data) => {
    return DUMMY_LIST.concat(Object.keys(data))
  }

  return (
    <div>
      <ColumnGrid>
        {costs.map((item, index) => (
          <Autocomplete
            disablePortal
            data-id={index}
            id={"" + index}
            options={getOptions(data)}
            sx={{ maxWidth: '300px', marginTop: "10px" }}
            onInputChange={(event, newJudgeName) => handleCostsChange(index, newJudgeName)}
            renderInput={(params) => <div>
              <TextField {...params} label={"שופט מספר " + (index + 1)} />
              <label>דרגה מתועדת: {getRank(item)}</label>
            </div>}
          />
        ))}
      </ColumnGrid>
      <Button variant="contained" onClick={addNewCost}>+</Button>
      <Button variant="contained" onClick={removeCost}>-</Button>
      <div style={{ display: 'grid', justifyItems: 'center', marginTop: '30px' }}>
        <Typography>
          כמות הקבוצות המאושרת לפי הקאפ: {maxCapCount}
        </Typography>
        <Paper sx={{ overflow: 'hidden' }}>
          <TableContainer sx={{ maxWidth: '450px', direction: 'ltr', marginTop: '30px', maxHeight: '100vh' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="right">SC</TableCell>
                  <TableCell align="right">C2</TableCell>
                  <TableCell align="right">C1</TableCell>
                  <TableCell align="right">W3</TableCell>
                  <TableCell align="right">W2</TableCell>
                  <TableCell align="right">W1</TableCell>
                  <TableCell align="right">כמות קבוצות</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cap && cap.map((capGroup, i) => (
                  capGroup.map((capLine, j) => (
                    <TableRow key={j} style={capRequirements && capRequirements[i][j] ? { background: '#d9ead3' } : { background: '#f4cccc' }}>
                      <TableCell align="right">{hideIfZero(capLine[0])}</TableCell>
                      <TableCell align="right">{hideIfZero(capLine[1])}</TableCell>
                      <TableCell align="right">{hideIfZero(capLine[2])}</TableCell>
                      <TableCell align="right">{hideIfZero(capLine[3])}</TableCell>
                      <TableCell align="right">{hideIfZero(capLine[4])}</TableCell>
                      <TableCell align="right">{hideIfZero(capLine[5])}</TableCell>
                      <TableCell align="right">{i + 1}</TableCell>
                    </TableRow>
                  ))
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

      </div>
    </div>
  );
};

export default Calculator;
