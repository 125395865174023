import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import CompetitionAdjudicators from "./CompetitionAdjudicators";
import CompetitionAteam from "./CompetitionAteam";
import CompetitionOutrounds from "./CompetitionOutrounds";
import ListRenderer from "./ListRenderer";
import { HebrewTableCell } from "./styles";
import { renderBoolean } from "../constants";


const Competitions = ({ competitions }) => {
  const currentYear = Object.values(competitions)
    .map(yearObj => Object.entries(yearObj))
    .flat()

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: '100vh' }}>
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            <HebrewTableCell>שם</HebrewTableCell>
            <HebrewTableCell>אייטים</HebrewTableCell>
            <HebrewTableCell>אאוטראונדס</HebrewTableCell>
            <HebrewTableCell>שופטים נוספים ששברו</HebrewTableCell>
            <HebrewTableCell>שופטים באינראונדס</HebrewTableCell>
            <HebrewTableCell>רשמית?</HebrewTableCell>
            <HebrewTableCell>ברייק כפול?</HebrewTableCell>
            <HebrewTableCell>וורלדס/יורוז?</HebrewTableCell>
            <HebrewTableCell>חו"ל?</HebrewTableCell>
            <HebrewTableCell>שווי לצורך חישוב W2</HebrewTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentYear.map(competition => (
            <TableRow >
              <HebrewTableCell style={{ direction: 'rtl', textAlign: 'right' }}>{competition[0]}</HebrewTableCell>
              <HebrewTableCell><CompetitionAteam ca={competition[1].ca} dca={competition[1].dca} aca={competition[1].aca} /></HebrewTableCell>
              <HebrewTableCell><CompetitionOutrounds outrounds={competition[1].outrounds} /></HebrewTableCell>
              <HebrewTableCell><ListRenderer list={competition[1].extraBreakingAdjudicators} /></HebrewTableCell>
              <HebrewTableCell><CompetitionAdjudicators trainees={competition[1].inroundTrainees} wings={competition[1].inroundWings} chairs={competition[1].inroundChairs} /></HebrewTableCell>
              <HebrewTableCell>{renderBoolean(competition[1].isOfficial)}</HebrewTableCell>
              <HebrewTableCell>{renderBoolean(competition[1].doubleBreak)}</HebrewTableCell>
              <HebrewTableCell>{renderBoolean(competition[1].isWEUDC)}</HebrewTableCell>
              <HebrewTableCell>{renderBoolean(competition[1].isForeign)}</HebrewTableCell>
              <HebrewTableCell>{competition[1].inroundCount / 3}</HebrewTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Paper>
  );
};

export default Competitions;
