import React from "react";
import { Table, Paper, TableBody, TableCell, TableContainer, TableHead, Select, MenuItem, TableRow } from "@mui/material";
import { defaultYear } from "./constants";

export default function JudgesTimeline({
  data,
  competitions
}) {
  const [year, setYear] = React.useState(defaultYear)

  const adjsObject = filterObj(data, x => x.minYear >= year)
  const adjs = Object.entries(adjsObject)
  const rankYears = adjs.reduce((acc, curr) => {
    const compValues = Object.values(curr[1].competitions)
    acc[curr[0]] = {
      firstRecorded: compValues[0].year,
      w1: { year: compValues[0].year, count: 0 },
      w2: getDebut(curr[1], compValues, isW2),
      w3: getDebut(curr[1], compValues, isW3),
      c1: getDebut(curr[1], compValues, isC1),
      c2: getDebut(curr[1], compValues, isC2),
      sc: getDebut(curr[1], compValues, isSC),
    }
    return acc
  }, {})

  function getDebut(judge, comps, fun) {
    if (!isRanked(judge.rank, "W2")) return { year: "", count: "" }
    for (let i = 0; i < comps.length; i++) {
      const arr = comps.slice(0, i + 1)
      if (fun(arr)) {
        return { year: comps[i].year, count: i + 1 }
      }
    }
    return { year: "", count: "" }
  }

  function isRanked(judgeRank, requiredRank) {
    if (judgeRank == "SC") return true
    if (requiredRank == "W1") return true
    if (requiredRank == "W2" && judgeRank != "W1") return true
    if (requiredRank == "W3" && judgeRank != "W1" && judgeRank != "W2") return true
    if (requiredRank == "C1" && (judgeRank == "C1" || judgeRank == "C2")) return true
    if (requiredRank == "C2" && judgeRank == "C2") return true
    return false
  }

  function isC2(data) {
    return isC2FirstOption(data) || isC2SecondOption(data)
  }

  function isSC(data) {
    return isSCFirstCriteria(data) || isSCSecondCriteria(data) || isSCThirdCriteria(data)
  }

  function isSCFirstCriteria(data) {
    return data.some(comp => {
      let isAteam = comp.ca || comp.dca || comp.aca
      return isAteam && comp.isWEUDC
    })
  }

  function isSCSecondCriteria(data) {
    return data.some(comp => {
      return comp.outroundChair && comp.isWEUDC
    })
  }

  function isSCThirdCriteria(data) {
    return data.filter(comp => {
      let isAteam = comp.ca || comp.dca || comp.aca
      return comp.outroundChair && comp.isOfficial && !isAteam
    }).length >= 3 && isC2FirstOption(data) && isC2SecondOption(data)
  }

  function isC1(data) {
    return getBreaksCount(data, true) >= 3
  }

  function isW3(data) {
    return data.filter(comp => {
      return comp.didBreak || comp.inroundChair
    }).length >= 2
  }

  function isW2(data) {
    return data.map(comp => {
      return Math.floor(comp.inroundCount / 3)
    }).reduce((a, b) => a + b, 0) >= 4
  }

  function isC2FirstOption(data) {
    return data.some(comp => {
      return comp.didBreak && comp.isWEUDC
    })
  }

  function getBreaksCount(data, includeDoubleBreak) {
    let count = data.filter(comp => {
      return comp.didBreak
    }).length
    let didDoubleBreak = data.some(comp => {
      return comp.didBreak && comp.doubleBreak
    })
    return count + (includeDoubleBreak && didDoubleBreak ? 1 : 0)
  }

  function isC2SecondOption(data) {
    return getBreaksCount(data, true) >= 8
  }


  return (
    <>
      הסתירו שופטים שלא נרשמה עבורם פעילות מאז
      <Select
        InputProps={{
          disableUnderline: true,
        }}

        id="json-url"
        label="Enter JSON url"
        defaultValue={defaultYear}
        onChange={(e) => setYear(e.target.value)}
      >
        {Object.keys(competitions).map((year) => (
          <MenuItem value={year}>{year}</MenuItem>
        )
        )}
      </Select>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '100vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="right">שם</TableCell>
                <TableCell align="right">תחילת פעילות מתועדת</TableCell>
                <TableCell align="right">כמות תחרויות עד W2</TableCell>
                <TableCell align="right">ממתי נחשב W2</TableCell>
                <TableCell align="right">כמות תחרויות עד W3</TableCell>
                <TableCell align="right">ממתי נחשב W3</TableCell>
                <TableCell align="right">כמות תחרויות עד C1</TableCell>
                <TableCell align="right">ממתי נחשב C1</TableCell>
                <TableCell align="right">כמות תחרויות עד C2</TableCell>
                <TableCell align="right">ממתי נחשב C2</TableCell>
                <TableCell align="right">כמות תחרויות עד SC</TableCell>
                <TableCell align="right">ממתי נחשב SC</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(rankYears).map((row) => (
                <TableRow key={row[0]}>
                  <TableCell align="right">
                    {row[0]}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].firstRecorded}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].w2.count}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].w2.year}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].w3.count}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].w3.year}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].c1.count}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].c1.year}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].c2.count}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].c2.year}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].sc.count}
                  </TableCell>
                  <TableCell align="right">
                    {row[1].sc.year}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
function filterObj(obj, predicate) {
  let result = {}, key;

  for (key in obj) {
    if (obj.hasOwnProperty(key) && predicate(obj[key])) {
      result[key] = obj[key];
    }
  }

  return result;
};