import { Paper } from "@mui/material";
import React from "react";

export default function DisclaimerNotice({ }) {
    return (
        <Paper style={{background: '#fce8b2', textAlign: 'right', fontSize: '14px'}}>
            <div>
                <span>הערה חשובה: המידע באתר מבוסס ברובו על ארכיון הטאבים הליגתי, שמכיל מידע חלקי על תחרויות החל מעונת 2013-14 בלבד, וכן על מידע מצומצם מתוך אליפות אירופה והעולם. שאר המידע באתר מדווח על ידי שופטים באופן ידני אם מעוניינים בכך. </span>
                <span><b>חברי אייטים: אין להסתמך על האתר לצורך חלוקת הקצאות בתחרות. </b></span>
                <span>נא להתייחס למידע שהשופט או המועדון דיווחו בטפסי הרישום בלבד.</span>
            </div>
            <br />
            <div>
                <span><a href="https://docs.google.com/spreadsheets/d/1dm1ddBWJK8-2ErVxfpOdeYPZyRFnwqHU3rkkY6LPr2w/edit?usp=sharing">לצפייה בדו"ח שלמות המידע בארכיון הטאבים הליגתי לחצו כאן</a></span>
            </div>
        </Paper>
    )
}