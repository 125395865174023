import React, { useState, useEffect } from 'react';
import { roomCount, sleep } from '../constants';

interface RoomCounterProps {

}

export const RoomCounter = ({
  ...props
}: RoomCounterProps) => {
  const [tabs, setTabs] = useState<any[] | null>(null)
  console.log(tabs)
  React.useEffect(() => {
    fetchData().catch((error) => { console.error("Unable to fetch data:", error) })
  }, [])

  const fetchData = async () => {
    let res = await fetch(window.location.hostname.includes("localhost") ? 'tabs.json' : '/api/get_tabs', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    if (!res.ok) {
      await sleep(500)
      fetchData()
      return
    }
    let data = await res.json()
    setTabs(data)
  }

  return (
    <table style={{ margin: '0 auto' }}>
      <tbody>
        {tabs && Object.entries(tabs).map(([tabName, tabInfo]) => <tr>
          <td>{tabName}</td>
          <td>{roomCount(tabInfo)}</td>
        </tr>
        )}
      </tbody>
    </table>
  );
};
